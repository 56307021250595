import React, { forwardRef } from 'react';
import { components, OptionProps, OptionTypeBase } from 'react-select';
import './ReactSelect.css';

type CustomOptionProps<OptionType extends OptionTypeBase> = OptionProps<OptionType>;

const CustomOption = <OptionType extends OptionTypeBase>(
	{ children, innerProps: innerPropsBase, ...props }: CustomOptionProps<OptionType>,
	ref: React.Ref<HTMLDivElement>,
) => {
	const { onMouseMove, onMouseOver, ...innerProps } = innerPropsBase;

	return (
		// @ts-expect-error
		<components.Option {...props} innerProps={innerProps} innerRef={ref} className="react-select-custom-option">
			{children}
		</components.Option>
	);
};

export default forwardRef(CustomOption) as <OptionType extends OptionTypeBase>(
	props: CustomOptionProps<OptionType> & { ref?: React.Ref<HTMLDivElement> },
) => ReturnType<typeof CustomOption>;

import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { MenuListComponentProps, OptionProps, OptionTypeBase } from 'react-select';

type CustomMenuListProps<OptionType extends OptionTypeBase> = MenuListComponentProps<OptionType>;

const CustomMenuList = <OptionType extends OptionTypeBase>({
	options,
	children,
	maxHeight,
	getValue,
}: CustomMenuListProps<OptionType>) => {
	const childrenArray = React.Children.toArray(children);
	const value = getValue();

	const parentRef = React.useRef<HTMLDivElement>(null);

	const virtualizer = useVirtualizer({
		estimateSize: (index) => {
			const child = childrenArray[index] as React.ReactElement;
			return child.props?.height || 40; // fallback to 40 if no height provided
		},
		initialOffset: options.indexOf(value as OptionType) * 40,
		count: childrenArray.length,
		getScrollElement: () => parentRef.current,
	});

	// Calculate dynamic height
	const totalHeight = childrenArray.length * 40; // Estimated total height of all items
	const dynamicHeight = Math.min(maxHeight, virtualizer.getTotalSize(), totalHeight);

	return (
		<div ref={parentRef} style={{ height: dynamicHeight, overflow: 'auto', width: '100%', contain: 'strict' }}>
			<div
				style={{
					height: virtualizer.getTotalSize(),
					width: '100%',
					position: 'relative',
				}}
			>
				{virtualizer.getVirtualItems().map((virtualItem) => (
					<div
						key={virtualItem.key}
						data-index={virtualItem.index}
						ref={virtualItem.measureElement}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							transform: `translateY(${virtualItem.start}px)`,
							height: 'auto',
						}}
					>
						{childrenArray[virtualItem.index]}
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomMenuList;

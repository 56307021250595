import { mapOptionsDataToObject } from 'components/global/Select/utils/optionsMapper';
import { WebWorkerFactoryV2 } from '../utility/WebWorkerFactoryV2';

export const mapOptionsDataToObjectWorkerScript = `({ data, key, value, label }) => {
    return (Array.isArray(data) ? data : []).reduce((previousMap, d) => ({
      ...previousMap,
      [d[key]]: {
        value: d[value],
      label: d[label],
      },
    }), {});
  }
`;

export const createMapOptionsDataToObjectWorker = () => {
	return new WebWorkerFactoryV2<typeof mapOptionsDataToObject>(mapOptionsDataToObjectWorkerScript);
};

import { createAction, createAsyncAction } from 'typesafe-actions';
import {
	AdminDashboardFilters,
	TotalSitesInfo,
	TotalUsersInfo,
	TotalLoginDevicesInfo,
	AvgLoginInfo,
	UsersCvUInfo,
	SitesCvUInfo,
	AvgVTotalReportsInfo,
	MAUInfo,
	StorageInfo,
} from './adminDashboardStore';
import * as types from './adminDashboard.actionTypes';
import { BaseSelectOptionType } from 'components/global/Select/utils/optionsMapper';

export const setFilters = createAction(types.SET_FILTERS, (filters: Partial<AdminDashboardFilters>) => ({ filters }))();

export const setOrgId = createAction(types.SET_ORG_ID, (orgId: string) => ({ orgId }))();
export const setShowErrorModal = createAction(types.SET_SHOW_ERROR_MODAL, (value: boolean) => ({ value }))();
export const setSelectedOrg = createAction(types.SET_SELECTED_ORG, (value: BaseSelectOptionType | null) => ({
	value,
}))();
export const setIsApply = createAction(types.SET_IS_APPLY, (value: boolean) => value)();

export const fetchTotalActiveSitesAsync = createAsyncAction(
	types.FETCH_TOTAL_ACTIVE_SITES_REQUEST,
	types.FETCH_TOTAL_ACTIVE_SITES_SUCCESS,
	types.FETCH_TOTAL_ACTIVE_SITES_FAILURE,
)<undefined, TotalSitesInfo, string>();

export const fetchTotalActiveUsersAsync = createAsyncAction(
	types.FETCH_TOTAL_ACTIVE_USERS_REQUEST,
	types.FETCH_TOTAL_ACTIVE_USERS_SUCCESS,
	types.FETCH_TOTAL_ACTIVE_USERS_FAILURE,
)<undefined, TotalUsersInfo, string>();

export const fetchTotalLoginDevicesAsync = createAsyncAction(
	types.FETCH_TOTAL_LOGIN_DEVICES_REQUEST,
	types.FETCH_TOTAL_LOGIN_DEVICES_SUCCESS,
	types.FETCH_TOTAL_LOGIN_DEVICES_FAILURE,
)<undefined, TotalLoginDevicesInfo, string>();

export const fetchAvgLoginInfoAsync = createAsyncAction(
	types.FETCH_AVG_LOGIN_INFO_REQUEST,
	types.FETCH_AVG_LOGIN_INFO_SUCCESS,
	types.FETCH_AVG_LOGIN_INFO_FAILURE,
)<undefined, AvgLoginInfo, string>();

export const fetchUsersCvUAsync = createAsyncAction(
	types.FETCH_USERS_CREATED_V_UPDATED_REQUEST,
	types.FETCH_USERS_CREATED_V_UPDATED_SUCCESS,
	types.FETCH_USERS_CREATED_V_UPDATED_FAILURE,
)<undefined, UsersCvUInfo[] | string, string>();

export const fetchSitesCvUAsync = createAsyncAction(
	types.FETCH_SITES_CREATED_V_UPDATED_REQUEST,
	types.FETCH_SITES_CREATED_V_UPDATED_SUCCESS,
	types.FETCH_SITES_CREATED_V_UPDATED_FAILURE,
)<undefined, SitesCvUInfo[] | string, string>();

export const fetchAvgVTotalReportsAsync = createAsyncAction(
	types.FETCH_AVG_V_TOTAL_REPORTS_REQUEST,
	types.FETCH_AVG_V_TOTAL_REPORTS_SUCCESS,
	types.FETCH_AVG_V_TOTAL_REPORTS_FAILURE,
)<undefined, AvgVTotalReportsInfo[] | string, string>();

export const fetchMAUAsync = createAsyncAction(
	types.FETCH_MAU_REQUEST,
	types.FETCH_MAU_SUCCESS,
	types.FETCH_MAU_FAILURE,
)<undefined, MAUInfo | string, string>();

export const fetchStorageAsync = createAsyncAction(
	types.FETCH_STORAGE_REQUEST,
	types.FETCH_STORAGE_SUCCESS,
	types.FETCH_STORAGE_FAILURE,
)<undefined, StorageInfo, string>();

import React, { useState, useRef, useEffect } from 'react';

// libraries
import { connect, DispatchProp, InferableComponentEnhancerWithProps, useDispatch } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { useTranslation } from 'react-i18next';

// ui components
import { Container } from './Auth.styles';

// utilities
import authenticateCredentials from '../utils/authenticateCredentials';
import authenticatePasswordReset from '../utils/authenticatePasswordReset';
import { debounce } from 'lodash';
import {
	findTenantByEmailDomain,
	authenticateFederatedCredentials,
	checkUserEnrollment,
	requestAccessEnrollment,
	authenticateMultiTenant,
	linkAccount,
} from 'services/federatedLogin.service';
import { setEnrollmentStatus, setAuthenticatingStatus } from 'reducers/userEnrollment/userEnrollment.action';
import { checkCharacter } from 'utils/checkCharacter';

// components
import AuthContainer from '../Auth/AuthContainer';

// types
import { RootState } from 'store/rootReducers';
import { FirebaseConnect } from 'types/app';
import { compose } from 'redux';
import { FederatedAuthentication } from '@nimbly-technologies/nimbly-common';
import postCheckIsSandbox from '../utils/authenticateIsSandboxApi';
import { toast } from 'react-toastify';
enum EnrollmentStatus {
	SUCCESS = 'SUCCESS',
	NOT_APPROVED = 'not-approved',
}

export interface SitePropsLoginData {
	/** (String) user email address, retrieved from form */
	email: string;
	/** (String) user input password, retrieved from form */
	password: string;
}

export interface SitePropsForgotPasswordData {
	/** (String) user email address, retrieved from forgot password form */
	email: string;
}

export interface SitePropsErrorFormFields {
	/** If true then email doesn't pass system validation */
	email: boolean;
	/** If true then password doesn't pass system validation */
	password: boolean;
	/** If true then email (in forgot password form) doesn't pass system validation */
	forgotPass: boolean;
}

export interface SitePropsFlashMessage {
	/** Error message that will be shown */
	errorMessage: string;
	/** Success message that will be shown */
	successMessage: string;
	/** Referes to SitePropsErrorFormFields, defining wether each form field is valid / not */
	hasError: SitePropsErrorFormFields;
}
interface TenantTypes {
	federatedAuthentication: FederatedAuthentication | null;
	isFederated: boolean;
	isOrganizationTenant: false;
}

export const Auth = (props: AuthProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [loginCredentials, setLoginCredentials] = useState<SitePropsLoginData>({
		email: '',
		password: '',
	});
	const [forgotPasswordData, setForgotPasswordData] = useState<SitePropsForgotPasswordData>({
		email: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [modeForgot, setModeForgot] = useState(false);
	const [siteFlashMessage, setSiteFlashMessage] = useState<SitePropsFlashMessage>({
		errorMessage: '',
		successMessage: '',
		hasError: {
			email: false,
			password: false,
			forgotPass: false,
		},
	});
	const [tenant, setTenant] = useState<TenantTypes>({
		isFederated: false,
		isOrganizationTenant: false,
		federatedAuthentication: null,
	});
	const [emailDomain, setEmailDomain] = useState<string>('');
	const [validationMessage, setValidationMessage] = useState<string>('');
	const [isRequestAccess, setIsRequestAccess] = useState<boolean>(false);
	const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false);
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [isRequestSuccess, setIsRequestSuccess] = useState<boolean>(false);
	const [isRequestFailed, setIsRequestFailed] = useState<boolean>(false);
	const [isMultipleRequest, setIsMultipleRequest] = useState<boolean>(false);
	const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
	const [idToken, setIdToken] = useState<string>('');
	/** Required to handle if the account is not yet linking */
	const [pendingCredential, setPendingCredential] = useState<any>(null);

	const setStatusMultipleRequest = () => {
		setIsLoading(false);
		setIsRequestAccess(true);
		setIsRequestFailed(true);
		setIsMultipleRequest(true);
	};

	const handleAccountExist = (pendingCred: any) => {
		setIsLoading(false);
		setPendingCredential(pendingCred);
		setTenant({ ...tenant, isFederated: false });
		setValidationMessage('Password is required to link the account');
	};

	const handleFailedResult = (result: any) => {
		setIsLoading(false);
		setSiteFlashMessage({
			...siteFlashMessage,
			errorMessage: result.errorMsg,
			hasError: {
				...siteFlashMessage.hasError,
				[result.errorField]: result.status !== 'field_not_complete',
			},
		});
	};

	const loginAsFederated = async () => {
		const getIDToken = await authenticateFederatedCredentials(
			tenant.federatedAuthentication as FederatedAuthentication,
			props.firebase,
		);

		/** When pop up cancelled/closed */
		if (getIDToken?.errorCode === 'auth/user-cancelled' || getIDToken?.errorCode === 'auth/popup-closed-by-user')
			return window.location.reload();

		/** Handle certain user get account-exist-with-different-creds */
		if (getIDToken?.errorCode === 'auth/account-exists-with-different-credential')
			return handleAccountExist(getIDToken?.pendingCred);

		setIdToken(getIDToken);
		const getUserEnrollmentStatus = await checkUserEnrollment(
			getIDToken,
			String(tenant?.federatedAuthentication?.tenantID),
		);

		// User Enrollment is not yet approved
		if (!getUserEnrollmentStatus.data && getUserEnrollmentStatus.message === EnrollmentStatus.NOT_APPROVED)
			return setStatusMultipleRequest();

		if (!getUserEnrollmentStatus.data) {
			setValidationMessage(t('message.loginScreen.noPermission'));
			setIsRequestAccess(true);
		} else {
			const status = getUserEnrollmentStatus.message === EnrollmentStatus.SUCCESS ? EnrollmentStatus.SUCCESS : null;
			dispatch(setEnrollmentStatus(status));
			setSiteFlashMessage({
				...siteFlashMessage,
				successMessage: t('message.loginScreen.successLogin'),
			});
		}
		setIsAuthenticating(false);
		dispatch(setAuthenticatingStatus(false));
		setIsLoading(false);
	};

	const loginAsMultiTenant = async () => {
		const validateLogin = await authenticateMultiTenant(
			tenant.federatedAuthentication as FederatedAuthentication,
			loginCredentials.email,
			loginCredentials.password,
		);

		if (validateLogin.status === 'ok') {
			dispatch(setEnrollmentStatus(EnrollmentStatus.SUCCESS));
			setSiteFlashMessage({
				...siteFlashMessage,
				successMessage: t('message.loginScreen.successLogin'),
			});
		}
		return handleFailedResult(validateLogin);
	};

	const loginAsNonFederated = async () => {
		const checkLoginFirebase = await authenticateCredentials(
			loginCredentials.email,
			loginCredentials.password,
			props.firebase,
		);

		if (checkLoginFirebase.status === 'ok') {
			dispatch(setEnrollmentStatus(EnrollmentStatus.SUCCESS));
			setSiteFlashMessage({
				...siteFlashMessage,
				successMessage: t('message.loginScreen.successLogin'),
			});
		}
		return handleFailedResult(checkLoginFirebase);
	};

	const linkingAccount = async () => {
		const checkLinkAccount = await linkAccount(loginCredentials.email, loginCredentials.password, pendingCredential);
		if (checkLinkAccount.status === 'ok') {
			dispatch(setEnrollmentStatus(EnrollmentStatus.SUCCESS));
			setSiteFlashMessage({
				...siteFlashMessage,
				successMessage: t('message.loginScreen.successLogin'),
			});
		}
		return handleFailedResult(checkLinkAccount);
	};

	const forgotPassword = async () => {
		const reset_password = await authenticatePasswordReset(forgotPasswordData.email, props.firebase);
		setIsLoading(false);

		if (reset_password.status === 'ok') {
			setForgotPasswordData({ email: '' });
			toast.success('Password reset link sent successfully.');
			setSiteFlashMessage({
				...siteFlashMessage,
				successMessage: t('message.loginScreen.passwordRequest'),
			});
		}

		setSiteFlashMessage({
			...siteFlashMessage,
			errorMessage: reset_password.errorMsg,
			hasError: {
				...siteFlashMessage.hasError,
				[reset_password.errorField]: reset_password.status !== 'field_not_complete',
			},
		});
	};

	const handleSubmitForm = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>,
	) => {
		e.preventDefault();
		setIsLoading(true);
		setSiteFlashMessage({ ...siteFlashMessage, successMessage: '', errorMessage: '' });

		if (tenant.federatedAuthentication && !tenant.isOrganizationTenant && tenant.isFederated) {
			dispatch(setAuthenticatingStatus(true));
			setIsAuthenticating(true);
			return loginAsFederated();
		}
		if (tenant.isOrganizationTenant) return loginAsMultiTenant();
		if (modeForgot) return forgotPassword();
		if (pendingCredential) return linkingAccount();

		await postCheckIsSandbox(loginCredentials.email);
		loginAsNonFederated();
	};

	const handleDismissRequestSuccess = () => {
		props.firebase.logout().then(() => {
			window.location.reload();
		});
	};

	const handleRequestAccess = async () => {
		setIsLoading(true);
		try {
			setValidationMessage('');
			const result = await requestAccessEnrollment(idToken, tenant.federatedAuthentication as FederatedAuthentication);
			if (!result) setIsRequestFailed(true);
			setIsRequestSuccess(true);
		} catch (error) {
			console.error(error);
		}
		setIsLoading(false);
	};

	const emailDomainCheck = (email: string) => {
		if (!email) return;
		const emailNameCheck = checkCharacter(email);
		if (!emailNameCheck) {
			setIsCheckingEmail(false);
			setEmailDomain('');
			return setSiteFlashMessage({
				...siteFlashMessage,
				errorMessage: t('message.loginScreen.emailNotValid'),
				hasError: { ...siteFlashMessage.hasError, email: true },
			});
		}
		const result = email.match(/.*/);
		// @ts-ignore
		const [match] = result;
		setEmailDomain(match);
		setIsCheckingEmail(false);
		setSiteFlashMessage({
			hasError: { email: false, password: false, forgotPass: false },
			successMessage: '',
			errorMessage: '',
		});
		setIsEmailValid(true);
	};

	const debounceEmail = debounce(emailDomainCheck, 1500);
	const checkEmailbyDebounce = useRef(debounceEmail);

	const findTenant = async (emailDomain: string, email: string) => {
		const clearValue = emailDomain.replace('@', '');
		const domainToLowerCase = clearValue.toLowerCase();

		try {
			// Disabled for now, no organization used federated login
			// const tenantResult = await findTenantByEmailDomain(domainToLowerCase, email);
			// if (!tenantResult) return setIsLoading(false);
			setTenant({
				...tenant,
				isFederated: false,
				isOrganizationTenant: false,
				federatedAuthentication: null,
			});
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const debounceTenant = debounce(findTenant, 1500);
	const checkTenantbyDebounce = useRef(debounceTenant);

	const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsEmailValid(false);
		setTenant({ ...tenant, isFederated: true });
		setValidationMessage('');
		setSiteFlashMessage({ ...siteFlashMessage, successMessage: '', errorMessage: '' });
		setLoginCredentials({ ...loginCredentials, email: e.target.value });
	};

	const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValidationMessage('');
		if (e.target.value === '') {
			setSiteFlashMessage({
				...siteFlashMessage,
				hasError: { ...siteFlashMessage.hasError, password: false },
			});
		}

		setLoginCredentials({ ...loginCredentials, password: e.target.value });
	};

	const handleToggleForgotPass = () => {
		setModeForgot(!modeForgot);
		setSiteFlashMessage({
			...siteFlashMessage,
			successMessage: '',
			errorMessage: '',
		});
	};

	/** Check for email address format on real time (when text field updated), for forgot password email field */
	const handleChangeForgotPass = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSiteFlashMessage({
			...siteFlashMessage,
			hasError: { ...siteFlashMessage.hasError, forgotPass: false },
		});

		setForgotPasswordData({ email: e.target.value });
	};

	useEffect(() => {
		if (!loginCredentials.email) return;
		setIsCheckingEmail(true);
		setIsEmailValid(false);
		checkEmailbyDebounce.current(loginCredentials.email);
	}, [loginCredentials.email]);

	useEffect(() => {
		if (!emailDomain) return;
		setLoginCredentials({ ...loginCredentials, password: '' });
		setIsLoading(true);

		checkTenantbyDebounce.current(emailDomain, loginCredentials.email);
	}, [emailDomain, loginCredentials.email]);

	return (
		<Container mode={{ modeForgot }}>
			<AuthContainer
				// Disabled for now, no organization used federated login
				isFederated={false}
				isRequestAccess={isRequestAccess}
				isCheckingEmail={isCheckingEmail}
				isEmailValid={isEmailValid}
				isRequestSuccess={isRequestSuccess}
				isRequestFailed={isRequestFailed}
				isAuthenticating={isAuthenticating}
				isMultipleRequest={isMultipleRequest}
				loginCredentials={loginCredentials}
				forgotPasswordData={forgotPasswordData}
				isLoading={isLoading}
				modeForgot={modeForgot}
				siteFlashMessage={siteFlashMessage}
				validationMessage={validationMessage}
				handleSubmitForm={handleSubmitForm}
				handleChangeEmail={handleChangeEmail}
				handleChangePassword={handleChangePassword}
				handleChangeForgotPass={handleChangeForgotPass}
				handleToggleForgotPass={handleToggleForgotPass}
				handleRequestAccess={handleRequestAccess}
				handleDismissRequestSuccess={handleDismissRequestSuccess}
			/>
		</Container>
	);
};

const mapStateToProps = (state: RootState) => ({
	auth: state.firebase.auth,
	location: state.router.location,
});

/** DO NOT CHANGE - Make props out of mapStateToProps function */
type StateProps = ReturnType<typeof mapStateToProps>;

/** Put any custom props used by other library such as redux or firebase */
type EnhancedProps = StateProps & DispatchProp & FirebaseConnect;

/** Put custom props here */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {}

export type AuthProps = OwnProps & EnhancedProps;

const enhance: InferableComponentEnhancerWithProps<EnhancedProps, OwnProps> = compose(
	connect(mapStateToProps),
	firebaseConnect(),
);

export default enhance(Auth);

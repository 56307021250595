// Cores
import cloneDeep from 'lodash/cloneDeep';
import { call, put, select, takeEvery } from 'redux-saga/effects';

// Constants and data types
import {
	ExtendedIssue,
	FunctionReturn,
	IssueMember,
	IssueTrackerFilter,
	MessageType,
	UpdateIssueRequest,
} from '@nimbly-technologies/nimbly-common';
import { RootState } from '../../store/rootReducers';
import * as nimblyApi from 'config/baseURL';
import { IssueSecondaryStatusType, IssueStatusType, IssueType } from 'components/issues/IssueTracker.types';
import { GetFilteredIssueRequest } from './issues.store';

// Libraries
import API from 'helpers/api';
import { compareMembersEquality } from './helpers/compareMembersEquality';

// Redux
import * as issueActions from './issues.action';
import { ApprovalDetailsI, ApproversListI, IssueReducerType, IssueWithIndex } from './issues.reducer';
import { queryStringify } from 'utils/router';
import moment from 'moment';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { fetchApproversList, fetchIssueApprovalData, getIssueCustomFilter } from 'services/issues/issues.service';

/**
 * Retrieve issues from database
 */
export function* fetchIssuesBatchSaga(action: ReturnType<typeof issueActions.fetchIssueBatchAsync.request>) {
	const issuesState = (state: RootState) => state.issues;
	const stateGetter: IssueReducerType = yield select(issuesState);
	const state = cloneDeep(stateGetter);

	try {
		const { queryLimit, isPageEnd, selectedIssue } = state;
		if (isPageEnd && action.payload.loadMore) {
			return;
		}

		const postData: GetFilteredIssueRequest = createFilterIssueRequestPayload(state);

		const token: string = yield call(API.getFirebaseToken);
		const url = nimblyApi.NIMBLY_API_ISSUES_LIST_V2;
		const response: Response = yield call(API.post, url, token, postData);
		const json = yield call(response.json.bind(response));
		let issuesResponseData: ExtendedIssue[] = json.data;
		const issuesLength = issuesResponseData.length;
		let newSelectedIssue: IssueWithIndex | null | undefined =
			action.payload.selectedIssueKey === null ? null : undefined;

		if (action.payload.selectedIssueKey) {
			const issueIndex = issuesResponseData.findIndex((element) => element.issueID === action.payload.selectedIssueKey);

			if (issueIndex > -1) {
				// add ts ignore for memberDetails, since memberDetails is inside issue but not yet updated in common
				// @ts-ignore
				newSelectedIssue = { ...issuesResponseData[issueIndex], index: issueIndex };
			} else {
				const getDetailResponse: Response = yield call(
					API.getRetry,
					nimblyApi.NIMBLY_API_ISSUES_DETAIL + action.payload.selectedIssueKey,
					token,
				);
				const getDetailResponseJson = yield getDetailResponse.json();
				const issue: ExtendedIssue = getDetailResponseJson.data;
				// add ts ignore for memberDetails, since memberDetails is inside issue but not yet updated in common
				// @ts-ignore
				newSelectedIssue = { ...issue, index: 0 };
				issuesResponseData = [issue, ...issuesResponseData];
			}
		} else if (selectedIssue?.index === 0) {
			issuesResponseData = issuesResponseData.filter((element) => element.issueID !== selectedIssue?.issueID);
		}

		const issues = action.payload.loadMore
			? (state.issuesData || []).concat(issuesResponseData)
			: [...issuesResponseData];

		const newIsPageEnd = queryLimit > issuesLength;

		yield put(
			issueActions.fetchIssueBatchAsync.success({ issues, selectedIssue: newSelectedIssue, isPageEnd: newIsPageEnd }),
		);
	} catch (err) {
		yield put(issueActions.fetchIssueBatchAsync.failure(err));
	}
}

function createFilterIssueRequestPayload(state: IssueReducerType): GetFilteredIssueRequest {
	const {
		sortBy,
		sortDirection,
		filters,
		filteredStartDate,
		filteredEndDate,
		filteredQuestion,
		queryLimit,
		page,
	} = state;

	const postData: GetFilteredIssueRequest = {
		limit: queryLimit,
		page: page,
		sortBy: sortBy,
		sortType: sortDirection,
		unreadStatus: 'true',
	};

	Object.entries(filters).forEach(([key, value]) => {
		if (value.length) {
			// @ts-ignore
			postData[key] = value;
		}
	});

	if (filteredStartDate && filteredEndDate) {
		postData.createdAt = {
			startDate: filteredStartDate.startOf('days').toDate(),
			endDate: filteredEndDate.endOf('days').toDate(),
		};
	}
	if (filteredQuestion && filteredQuestion !== '') {
		postData.search = filteredQuestion;
	}

	if (filters.qIssueType.includes(IssueType.REPORT)) {
		// @ts-ignore
		postData.qIssueType = [...postData.qIssueType, IssueType.REPORT_ADHOC, IssueType.REPORT_SCHEDULED];
	}

	return postData;
}

/**
 * Compare selected issue to check for any update. If any
 * update is made, a request will be sent to the server to
 * update the data.
 *
 */
// eslint-disable-next-line complexity
export function* updateIssuesSingleSaga(action: ReturnType<typeof issueActions.updateIssuesSingleAsync.request>) {
	const stateSelector = (state: RootState) => state;
	const state: RootState = yield select(stateSelector);
	const { selectedIssue, issuesData } = state.issues;
	const { users } = state.users;
	const token: string = yield call(API.getFirebaseToken);

	let issueResponseFinal: any = null;
	const newIssues = cloneDeep(state.issues.issuesData);

	if (!selectedIssue) {
		return false;
	}

	try {
		if (!issuesData || !users) {
			yield put(
				issueActions.updateIssuesSingleAsync.success({
					issue: { ...selectedIssue, ...issueResponseFinal.data },
					issues: newIssues || [],
				}),
			);
			return;
		}

		// Compare data to be send to the API for update
		const previousIssue: ExtendedIssue = issuesData[selectedIssue.index];
		const currentIssue: ExtendedIssue = selectedIssue;
		const queryIssueData: UpdateIssueRequest = {
			issueID: currentIssue.issueID,
			priorityV2: previousIssue.priorityV2.key,
			type: previousIssue.type,
		};
		const hasChanges: MessageType[] = [];

		if (previousIssue && currentIssue) {
			// Update assigned departments
			if (previousIssue.assignedDepartments[0] !== currentIssue.assignedDepartments[0]) {
				hasChanges.push('assignedDepartment');
				queryIssueData.assignedDepartments = currentIssue.assignedDepartments?.[0]
					? currentIssue.assignedDepartments
					: [];
			}

			// Update assigned to user
			if (previousIssue.assignedTo.key !== currentIssue.assignedTo.key) {
				hasChanges.push('assignedTo');
				queryIssueData.assignedTo = currentIssue.assignedTo.key;
			}

			// Update issue due date
			if (currentIssue.dueDate && !moment(currentIssue.dueDate).isSame(moment(previousIssue.dueDate))) {
				hasChanges.push('dueDate');
				queryIssueData.dueDate = currentIssue.dueDate.toISOString();
			}

			// Update issue priority
			if (previousIssue.priorityV2 !== currentIssue.priorityV2) {
				hasChanges.push('priority');
				queryIssueData.priorityV2 = currentIssue.priorityV2.key;
			}

			// Update issue severity
			if (previousIssue.severity !== currentIssue.severity) {
				hasChanges.push('severity');
				queryIssueData.severity = currentIssue.severity;
			}

			// Update issue status
			if (previousIssue.status !== currentIssue.status) {
				hasChanges.push('status');
				queryIssueData.status = currentIssue.status;

				if (currentIssue.status === IssueStatusType.BLOCKED) {
					queryIssueData.hasBlockedReason = true;
				}
				if (currentIssue.status === 'resolved') {
					queryIssueData.resolvedAt = new Date().toISOString();
					queryIssueData.resolvedBy = state.firebase.auth.uid;
				}
			}

			// Update issue members should the previous value has been updated
			if (!compareMembersEquality(previousIssue.members, currentIssue.members)) {
				hasChanges.push('members');
				let tempMembers: IssueMember = {};
				Object.keys(currentIssue.members).forEach((value: string) => {
					tempMembers[value] = true;
				});
				queryIssueData.members = tempMembers;
			}

			// Should any changes detected will execute this following code
			if (hasChanges.length > 0) {
				const response: Response = yield call(API.put, nimblyApi.NIMBLY_API_ISSUES_UPDATE, token, {
					...queryIssueData,
					triggerNotification: true,
					version: 'v2',
				});
				issueResponseFinal = yield response.json();

				if (newIssues) {
					if (issueResponseFinal.data?.assignedTo) {
						const name = state.users.usersDataVisibility?.[issueResponseFinal.data.assignedTo]?.displayName;
						issueResponseFinal.data.assignedTo = {
							key: issueResponseFinal.data.assignedTo,
							name,
						};
					}
					if (issueResponseFinal.data?.priorityV2) {
						issueResponseFinal.data.priorityV2 = {
							key: issueResponseFinal.data.priorityV2,
							name: '',
						};
					}
					newIssues[selectedIssue.index] = { ...currentIssue, ...issueResponseFinal.data };
				}

				if (response.status !== 200) {
					const issueData = {
						...previousIssue,
						index: selectedIssue.index,
						memberDetails: selectedIssue.memberDetails,
					};
					yield put(issueActions.selectIssue(issueData));
				}
			}
		}

		action.payload.onSuccess?.();
		yield put(
			issueActions.updateIssuesSingleAsync.success({
				issue: { ...selectedIssue, ...issueResponseFinal.data },
				issues: newIssues || [],
			}),
		);
	} catch (err) {
		yield put(issueActions.updateIssuesSingleAsync.failure(err));
	}
}

/**
 * Create issue.
 * @param action
 *
 */
export function* createIssueSaga(action: ReturnType<typeof issueActions.createIssueAsync.request>) {
	const stateSelector = (state: RootState) => state;
	const state: RootState = (yield select(stateSelector)) as RootState;

	try {
		const token: string = yield call(API.getFirebaseToken);

		let issueResponseFinal: any = null;

		/** This request is sent to create new issue */
		if (action.payload.issue) {
			const response: Response = yield call(API.post, nimblyApi.NIMBLY_API_ISSUES_CREATE, token, {
				...action.payload.issue,
				triggerNotification: true,
			});

			issueResponseFinal = yield response.json();
		}

		yield put(issueActions.createIssueAsync.success(undefined));
	} catch (err) {
		yield put(issueActions.createIssueAsync.failure(err));
	}
}

/**
 * Create issue v2.
 * @param action
 *
 */
export function* createIssueV2Saga(action: ReturnType<typeof issueActions.createIssueV2Async.request>) {
	try {
		if (!action.payload.issue) {
			return;
		}

		const token: string = yield call(API.getFirebaseToken);

		/** This request is sent to create new issue */
		const response: Response = yield call(API.postRetry, nimblyApi.NIMBLY_API_ISSUES_CREATE_V2, token, {
			...action.payload.issue,
			triggerNotification: true,
		});

		if (response.status !== 200) {
			yield put(issueActions.createIssueV2Async.failure(new Error('')));
			return;
		}

		const responseJson = yield response.json();
		const issueId = responseJson.data;

		const issuesState = (state: RootState) => state.issues;
		const stateGetter: IssueReducerType = yield select(issuesState);
		const state = cloneDeep(stateGetter);

		const getDetailResponse: Response = yield call(API.getRetry, nimblyApi.NIMBLY_API_ISSUES_DETAIL + issueId, token);
		const getDetailResponseJson = yield getDetailResponse.json();
		const issue: ExtendedIssue = getDetailResponseJson.data;

		const issues = [getDetailResponseJson.data, ...(state.issuesData || [])];
		// add ts ignore for memberDetails, since memberDetails is inside issue but not yet updated in common
		// @ts-ignore
		const selectedIssue: IssueWithIndex = { index: 0, ...issue };

		yield put(
			issueActions.createIssueV2Async.success({
				issues: issues,
				selectedIssue: selectedIssue,
			}),
		);
	} catch (err) {
		yield put(issueActions.createIssueV2Async.failure(err));
	}
}

/**
 * Quick resolve issue.
 */
export function* quickResolveIssueSaga(action: ReturnType<typeof issueActions.quickResolveIssueAsync.request>) {
	try {
		const selectedIssue: IssueWithIndex = yield select((state: RootState) => state.issues.selectedIssue);
		const token: string = yield call(API.getFirebaseToken);
		const response: Response = yield call(API.put, nimblyApi.NIMBLY_API_ISSUES_LIST + '/quickResolve', token, {
			issueID: action.payload.issueID,
			triggerNotification: true,
		});
		const responseJson: FunctionReturn<Partial<IssueWithIndex>> = yield response.json();

		if (response.status !== 200) {
			yield put(issueActions.quickResolveIssueAsync.failure(new Error(responseJson.message)));
			toast.error(responseJson.message);
			return;
		}

		yield put(
			issueActions.quickResolveIssueAsync.success({
				issue: {
					...selectedIssue,
					...responseJson.data,
					secondaryStatus:
						selectedIssue.secondaryStatus === IssueSecondaryStatusType.OVERDUE
							? IssueSecondaryStatusType.BEHIND_TIME
							: IssueSecondaryStatusType.ON_TIME,
				},
			}),
		);
		toast.success(i18n.t('success.issuePage.quickResolveSuccess'));
	} catch (err) {
		yield put(issueActions.quickResolveIssueAsync.failure(err));
	}
}

export function* fetchCustomIssueFilters(action: ReturnType<typeof issueActions.fetchIssueCustomFiltersAsync.request>) {
	try {
		const data: IssueTrackerFilter[] = yield call(getIssueCustomFilter);
		yield put(issueActions.fetchIssueCustomFiltersAsync.success({ customFilters: data }));

		const defaultFilter = data.find(({ isDefault }) => isDefault);
		if (action.payload.shouldApplyDefault && defaultFilter) {
			// @ts-ignore
			yield put(issueActions.setIssuesFilters(defaultFilter?.filterDetail));
			yield put(
				issueActions.setSelectedCustomFilter({
					key: defaultFilter?.issueTrackerFilterID,
					name: defaultFilter.filterName,
				}),
			);
		}
	} catch (err) {
		yield put(issueActions.quickResolveIssueAsync.failure(err));
	}
}

// Get approval details

export function* fetchIssueApprovalDataSaga(
	action: ReturnType<typeof issueActions.fetchIssueApprovalDataAsync.request>,
): Generator {
	try {
		const issueId = action.payload.issueID;
		const res = (yield call(fetchIssueApprovalData, issueId)) as ApprovalDetailsI | undefined;
		if (res) {
			yield put(issueActions.fetchIssueApprovalDataAsync.success(res));
		}
	} catch (error) {
		yield put(issueActions.fetchIssueApprovalDataAsync.failure('Failed to get issue approval'));
	}
}

export function* fetchApproversListSaga(
	action: ReturnType<typeof issueActions.fetchApproversListAync.request>,
): Generator {
	try {
		const requesterID = action.payload.requesterID;
		const res = (yield call(fetchApproversList, requesterID)) as ApproversListI[];
		if (res) {
			yield put(issueActions.fetchApproversListAync.success(res));
		}
	} catch (error) {
		yield put(issueActions.fetchApproversListAync.failure('Failure to get approvers list'));
	}
}

export default function* issuesSaga() {
	yield takeEvery(issueActions.createIssueAsync.request, createIssueSaga);
	yield takeEvery(issueActions.createIssueV2Async.request, createIssueV2Saga);
	yield takeEvery(issueActions.fetchIssueBatchAsync.request, fetchIssuesBatchSaga);
	yield takeEvery(issueActions.updateIssuesSingleAsync.request, updateIssuesSingleSaga);
	yield takeEvery(issueActions.quickResolveIssueAsync.request, quickResolveIssueSaga);
	yield takeEvery(issueActions.fetchIssueCustomFiltersAsync.request, fetchCustomIssueFilters);
	yield takeEvery(issueActions.fetchIssueApprovalDataAsync.request, fetchIssueApprovalDataSaga);
	yield takeEvery(issueActions.fetchApproversListAync.request, fetchApproversListSaga);
}

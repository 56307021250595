import React, { useState, useEffect, ReactNode } from 'react';

interface SuspenseRenderProps {
	delay?: number;
	exclusive?: boolean;
	fallback: ReactNode;
}

const renderQueue: (() => void)[] = [];

const DEFAULT_DELAY = 100;

/**
 * Custom hook to handle delayed rendering and exclusive rendering logic.
 *
 * @param {number} delay - The delay in milliseconds before rendering.
 * @param {boolean} exclusive - If true, ensures no two components render simultaneously.
 */
const useSuspenseInitialRender = (delay: number, exclusive: boolean) => {
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		let timeoutId: number;

		const triggerRender = () => {
			timeoutId = window.setTimeout(() => {
				setShouldRender(true);
				if (exclusive) {
					renderQueue.shift();
					if (renderQueue.length > 0) {
						renderQueue[0]();
					}
				}
			}, delay);
		};

		if (exclusive) {
			renderQueue.push(triggerRender);
			if (renderQueue.length === 1) {
				triggerRender();
			}
		} else {
			triggerRender();
		}

		return () => {
			clearTimeout(timeoutId);
			if (exclusive) {
				renderQueue.shift();
			}
		};
	}, [delay, exclusive, setShouldRender]);

	return shouldRender;
};

/**
 * Delays rendering of a component or its children.
 *
 * @template T - The type of the component props.
 * @param {React.ComponentType<T>} WrappedComponent - The component to be rendered with a delay.
 * @param {SuspenseRenderProps} options - Options for the delay and fallback behavior.
 * @returns {React.FC<T>} - The wrapped component with delayed rendering.
 */
// eslint-disable-next-line no-undef
function withSuspenseInitialRender<T extends JSX.IntrinsicAttributes>(
	WrappedComponent: React.ComponentType<T>,
	options: SuspenseRenderProps,
) {
	const { delay = DEFAULT_DELAY, exclusive = false, fallback } = options;

	return (props: T) => {
		const shouldRender = useSuspenseInitialRender(delay, exclusive);

		// eslint-disable-next-line react/jsx-no-useless-fragment
		return shouldRender ? <WrappedComponent {...props} /> : <>{fallback}</>;
	};
}

/**
 * Suspense component to delay rendering of its children.
 *
 * @param {SuspenseRenderProps & { children: ReactNode }} props - Props for the Suspense component.
 * @returns {React.ReactElement} - The Suspense component.
 */
const SuspenseInitialRender: React.FC<SuspenseRenderProps & { children: ReactNode }> = ({
	children,
	delay = DEFAULT_DELAY,
	exclusive = false,
	fallback,
}) => {
	const shouldRender = useSuspenseInitialRender(delay, exclusive);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return shouldRender ? <>{children}</> : <>{fallback}</>;
};

export { SuspenseInitialRender, withSuspenseInitialRender };

export type MainSidebarOptions = 'analytics' | 'issues' | 'admin' | 'settings' | 'help' | 'superadmin';

export type LangOptions = 'en' | 'id' | 'pt' | 'es' | 'th' | 'cmn' | 'ko';
interface LangOptionsInfo {
	lang: LangOptions;
	label: string;
}
export const LANG_OPTIONS: LangOptionsInfo[] = [
	{
		lang: 'en',
		label: 'ENGLISH',
	},
	{
		lang: 'id',
		label: 'INDONESIA',
	},
	{
		lang: 'pt',
		label: 'PORTUGUESE',
	},
	{
		lang: 'es',
		label: 'SPANISH',
	},
	{
		lang: 'th',
		label: 'THAI',
	},
	{
		lang: 'cmn',
		label: 'MANDARIN',
	},
	{
		lang: 'ko',
		label: 'KOREAN',
	},
];

import * as Common from 'nimbly-common';
import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

// typings
import { RootState } from '../store/rootReducers';
import { AccessPermissionsPage, UserPermissionValue, UserAccess } from 'components/permissions/typings';

// utils
import getNestedObject from '../utils/getNestedObject';
import { apiURL } from '../config/baseURL';
import { getToken } from './api';
import API from 'helpers/api';
import { setToSession, checkExpiredStorageItem } from 'helpers/sessionStorageHelper';
import { LmsRoleAccess } from '../utils/lmsRoleAccess/type';
import { getAccessObject } from '../utils/lmsRoleAccess/lmsRoleAccess';

export const UPDATE_ACCESS_PERMISSIONS = 'userRole/UPDATE_ACCESS_PERMISSIONS';
export const RESET_ROLE_STATE = 'userRole/REST_ROLE_STATE';
export const SET_MODAL_STATUS = 'userRole/SET_MODAL_STATUS';
export const SET_IS_LOADING = 'userRole/SET_IS_LOADING';

interface FetchOptions {
	method: string;
	headers: {
		'Content-Type': string;
		authorization: string;
	};
	body?: string;
}

const USERROLES = 'USERROLES';

export const updateAccessPermission = (payload: Partial<UserAccessState>) =>
	action(UPDATE_ACCESS_PERMISSIONS, { payload });

export const setIsLoading = (bool: boolean) => action(SET_IS_LOADING, { bool });

/**
 * function to set the user's acess permission
 * this is to be called on initial page load where all page resource of the user's access is set
 */
export const setUserAccessPermission = () => async (dispatch: Dispatch, getState: () => RootState) => {
	dispatch(setIsLoading(true));

	const state = getState();
	const clonedUserAccessState = cloneDeep(state.userAccess) as UserAccessStateMutable;
	const token: string = await API.getFirebaseToken();
	const options: FetchOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: token,
		},
	};
	let roles = checkExpiredStorageItem<any>(USERROLES);
	let res;
	let result;
	let response;
	if (!roles) {
		response = await fetch(`${apiURL}/user-roles/role-for-user/`, options);
		const jsonResult = await response.json();
		setToSession(jsonResult, USERROLES);
		res = response;
		result = jsonResult;
	} else {
		result = roles;
		res = { status: 200 };
	}
	if (res.status !== 200 || !result.data || !result.data.resources) {
		toast.error('Error: Failed to retrieve user access. Please refresh and try again.');
		dispatch(setIsLoading(false));
		return Promise.resolve();
	}

	if (!result || !result.data) {
		dispatch(setIsLoading(false));
		return;
	}
	const level = result.data?.level;
	const resources = result.data?.resources;
	const role = result.data?.role;
	const organizationID = result.data?.organizationID;
	const lmsRole = result.data?.lmsRole?.role;
	const isLmsRoleActive = result.data?.lmsRole?.status === 'active';

	if (level) {
		clonedUserAccessState.level = level;
	}

	if (role) {
		clonedUserAccessState.role = role;
	}

	if (lmsRole && isLmsRoleActive) {
		clonedUserAccessState.lmsRole = lmsRole;
		clonedUserAccessState.lmsRoleAccess = await getAccessObject(lmsRole);
	}

	if (organizationID) {
		clonedUserAccessState.organizationID = organizationID;
	}

	resources.forEach((access: Common.Resource) => {
		const resultPermissions = access.permission;
		const resourcePath = access.resource.split(':');
		const permissions = getNestedObject(clonedUserAccessState, resourcePath) as UserAccess;
		const clonedPermissions = cloneDeep(permissions) || {};

		resultPermissions.forEach((permission: Common.enums.Permission) => {
			if (!clonedPermissions?.permissions) {
				clonedPermissions.permissions = {};
			}
			clonedPermissions.permissions[permission] = true;
		});

		set(clonedUserAccessState, resourcePath.join('.'), clonedPermissions);
	});

	dispatch(setIsLoading(false));
	dispatch(updateAccessPermission(clonedUserAccessState));
};

/**
 * checks user's page/feature permission of the @access resource
 * will update the state resource if any changes has recently been made by user's superior
 * @param access of type Resource
 */
export const checkUserAcessPermission = (access: Common.Resource) => async (
	dispatch: Dispatch,
	getState: () => RootState,
): Promise<boolean> => {
	const state = getState();
	const clonedUserAccessState = cloneDeep(state.userAccess) as UserAccessState;
	const token = await getToken();
	const options: FetchOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: token,
		},
	};
	const res = await fetch(`${apiURL}/user-roles/permissions/${access.resource}`, options);

	let viewAccess = false;
	if (res.status !== 200) {
		return Promise.resolve(viewAccess);
	}

	const result = await res.json();

	if (result?.data) {
		const resultPermissions = result.data;
		const resourcePath = access.resource.split(':');
		const permissions = getNestedObject(clonedUserAccessState, resourcePath) as UserPermissionValue;

		resultPermissions.forEach((permission: Common.enums.Permission) => {
			permissions[permission] = true;
			if (permission === 'view') {
				viewAccess = true;
			}
		});

		set(clonedUserAccessState, resourcePath.join('.'), permissions);
		dispatch(updateAccessPermission(clonedUserAccessState));
	}
	return Promise.resolve(viewAccess);
};

export const setModalStatus = (status: boolean) => action(SET_MODAL_STATUS, { status });

interface AccessPermissionModal {
	status: boolean;
}

export type UserAccessState = AccessPermissionsPage & {
	readonly isLoading: boolean;
	readonly isLoaded: boolean;

	readonly role: string;
	readonly organizationID: string;
	readonly level: number | null;
	readonly accessPermissionModal: AccessPermissionModal;

	readonly lmsRole: string | null;
	readonly lmsRoleAccess: LmsRoleAccess | null;
};

export type UserAccessStateMutable = AccessPermissionsPage & {
	isLoading: false;
	isLoaded: true;

	role: string;
	organizationID: string;
	level: number | null;
	accessPermissionModal: AccessPermissionModal;

	lmsRole: string | null;
	lmsRoleAccess: LmsRoleAccess | null;
};

const initialState: UserAccessState = {
	isLoading: false,
	isLoaded: false,

	role: '',
	organizationID: '',
	'setting-permission': {
		general: { all: { permissions: { view: true, edit: false } } },
		access: { all: { permissions: { view: false, edit: false } } },
		integration: { all: { permissions: { view: true, create: false } } },
		'role-manager': { all: { permissions: { view: false, edit: false } } },
		federated: { all: { permissions: { view: false, edit: false } } },
	},
	admin: {
		department: { all: { permissions: { view: false, create: false, edit: false } } },
		questionnaire: { all: { permissions: { view: false, create: false, edit: false, delete: false } } },
		user: {
			all: { permissions: { view: false, create: false, edit: false } },
			// TODO: feature to change user's role is yet to be implemented
			role: { permissions: { edit: false, delete: false } },
		},
		sites: {
			all: { permissions: { view: false, create: false, edit: false, delete: false } },
			'reporting-schedule': { permissions: { view: false, edit: false, delete: false } },
			report: { permissions: { view: false } },
		},
		inventory: { all: { permissions: { view: false, create: false, edit: false, delete: false } } },
		'stock-audit-scheduler': { all: { permissions: { view: false, create: false, edit: false, delete: false } } },
		'purchase-order': {
			all: { permissions: { view: false, create: false } },
			// TODO: feature to allow user to print PO is yet to be implemented
			print: { permissions: { view: false } },
		},
		'non-operational-days': { all: { permissions: { view: false } }, custom: { permissions: { view: false } } },
		'download-manager': { all: { permissions: { view: false } }, custom: { permissions: { view: false } } },
		'sales-target': { all: { permissions: { view: false, create: false, edit: false } } },
		promotion: { all: { permissions: { view: false, create: false } } },
		broadcast: { all: { permissions: { view: false, create: false } } },
		'customer-feedback': {
			campaign: { permissions: { view: false, create: false, edit: false } },
			qr: { permissions: { view: false } },
			report: { permissions: { view: false } },
		},
	},
	dashboard: {
		overview: { all: { permissions: { view: false } } },
		trends: { all: { permissions: { view: false } } },
		sites: { all: { permissions: { view: false } } },
		issues: { all: { permissions: { view: false } } },
		users: { all: { permissions: { view: false } } },
		inventory: { all: { permissions: { view: false } } },
		'site-with-low-stock': { all: { permissions: { view: false } } },
		'sales-calculations': { all: { permissions: { view: false } } },
		'issue-tracker': { all: { permissions: { view: false } } },
	},
	'issue-tracker': {
		issues: { all: { permissions: { view: false } }, approve: { permissions: { edit: false } } },
		assignment: {
			'create-issue': { permissions: { create: false } },
			'update-status': { permissions: { edit: false } },
			department: { permissions: { edit: false } },
			'assign-user': { permissions: { edit: false } },
			'change-due-date': { permissions: { edit: false } },
			'add-member': { permissions: { create: false } },
			'create-category': { permissions: { create: false } },
		},
	},
	fmcg: {
		'competitor-analysis': {
			all: {
				permissions: { view: false, create: false, edit: false, delete: false },
			},
		},
		'journey-plan': {
			all: {
				permissions: { view: false, create: false, edit: false, delete: false },
			},
		},
	},
	application: {
		report: {
			'ad-hoc': {
				permissions: { view: false, create: false },
			},
		},
	},
	authentication: {
		federated: {
			all: {
				permissions: { view: false, create: false, edit: false, delete: false },
			},
		},
	},
	level: null,
	accessPermissionModal: {
		status: false,
	},

	lmsRole: null,
	lmsRoleAccess: null,
};

export const userAccessThunks = { checkUserAcessPermission };
export const userAccessActions = { setModalStatus, updateAccessPermission };

export type UserAcessThunks = ActionType<typeof userAccessThunks>;
export type UserAcessActions = ActionType<typeof userAccessActions>;

export function userAccessReducer(state = initialState, action: UserAcessActions): UserAccessState {
	switch (action.type) {
		case UPDATE_ACCESS_PERMISSIONS:
			return {
				...state,
				...action.payload.payload,
				isLoading: false,
				isLoaded: true,
			};
		case SET_MODAL_STATUS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				accessPermissionModal: {
					status: action.payload.status,
				},
			};
		default:
			return state;
	}
}

export const SET_FILTER_DATE = '@REPORTSHUB/SET_FILTER_DATE';
export const SET_TABLE_TYPE = '@REPORTSHUB/SET_TABLE_TYPE';
export const SET_IS_FILTER_OPEN = '@REPORTSHUB/SET_FILTER_OPEN';

export const SET_PAGE_INDEX = '@REPORTSHUB/SET_PAGE_INDEX';
export const SET_PAGE_SORT = '@REPORTSHUB/SET_PAGE_SORT';

export const SET_SINGLE_SELECT = '@REPORTSHUB/SET_SINGLE_SELECT';
export const SET_MULTI_SELECT = '@REPORTSHUB/SET_MULTI_SELECT';
export const SET_RADIO_VALUE = '@REPORTSHUB/SET_RADIO_VALUE';
export const SET_REPORT_TYPE = '@REPORTSHUB/SET_REPORT_TYPE';

export const RESET_REPORT_COUNT = '@REPORTSHUB/RESET_REPORT_COUNT';
export const RESET_FILTERS = '@REPORTSHUB/RESET_FILTERS';

export const SET_REPORT_COUNT_SUCCESS = '@REPORTSHUB/SET_REPORT_COUNT_SUCCESS';
export const SET_LIMIT_SPEC = '@REPORTSHUB/SET_LIMIT_SPEC';

export const SET_CUSTOM_FILTER_MODAL = '@REPORTSHUB/SET_CUSTOM_FILTER_MODAL';
export const SET_CUSTOM_FILTER = '@REPORTSHUB/SET_CUSTOM_FILTER';
export const SET_IS_SAVED_FILTER = '@REPORTSHUB/SET_IS_SAVED_FILTER';

export const FETCH_REPORTSHUB_TABLE_REQUEST = '@REPORTSHUB/FETCH_REPORTSHUB_TABLE_REQUEST';
export const FETCH_REPORTSHUB_TABLE_SUCCESS = '@REPORTSHUB/FETCH_REPORTSHUB_TABLE_SUCCESS';
export const FETCH_REPORTSHUB_TABLE_FAILURE = '@REPORTSHUB/FETCH_REPORTSHUB_TABLE_FAILURE';

export const FETCH_REPORTSCOUNT_REQUEST = '@REPORTSHUB/FETCH_REPORTSCOUNT_REQUEST';
export const FETCH_REPORTSCOUNT_SUCCESS = '@REPORTSHUB/FETCH_REPORTSCOUNT_SUCCESS';
export const FETCH_REPORTSCOUNT_FAILURE = '@REPORTSHUB/FETCH_REPORTSCOUNT_FAILURE';

export const FETCH_REPORTS_FILTERS_REQUEST = '@REPORTSHUB/FETCH_REPORTS_FILTERS_REQUEST';
export const FETCH_REPORTS_FILTERS_SUCCESS = '@REPORTSHUB/FETCH_REPORTS_FILTERS_SUCCESS';
export const FETCH_REPORTS_FILTERS_FAILURE = '@REPORTSHUB/FETCH_REPORTS_FILTERS_FAILURE';

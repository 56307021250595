export interface OrgOption {
	orgID: string;
	name: string;
}

export const getOrgOptions = (orgList: Array<OrgOption>) => {
	const data = orgList;
	const options: any = [];
	if (data && data.length > 0) {
		data.forEach((org: any) => {
			const option = {
				value: org.orgID,
				label: org.name,
			};
			options.push(option);
		});
		return options;
	}
	return undefined;
};

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import en_button from './lang/en/button.json';
import en_error from './lang/en/error.json';
import en_success from './lang/en/success.json';
import en_label from './lang/en/label.json';
import en_message from './lang/en/message.json';
import en_modal from './lang/en/modal.json';
import en_nav from './lang/en/nav.json';
import en_placeholder from './lang/en/placeholder.json';
import en_time from './lang/en/time.json';
import en_toast from './lang/en/toast.json';
import en_pageQuestionnaire from './lang/en/pageQuestionnaire.json';
import en_addOn from './lang/en/addOn.json';

import id_button from './lang/id/button.json';
import id_error from './lang/id/error.json';
import id_success from './lang/id/success.json';
import id_label from './lang/id/label.json';
import id_message from './lang/id/message.json';
import id_modal from './lang/id/modal.json';
import id_nav from './lang/id/nav.json';
import id_placeholder from './lang/id/placeholder.json';
import id_time from './lang/id/time.json';
import id_toast from './lang/id/toast.json';
import id_pageQuestionnaire from './lang/id/pageQuestionnaire.json';
import id_addOn from './lang/id/addOn.json';

import pt_button from './lang/pt/button.json';
import pt_error from './lang/pt/error.json';
import pt_success from './lang/pt/success.json';
import pt_label from './lang/pt/label.json';
import pt_message from './lang/pt/message.json';
import pt_modal from './lang/pt/modal.json';
import pt_nav from './lang/pt/nav.json';
import pt_placeholder from './lang/pt/placeholder.json';
import pt_time from './lang/pt/time.json';
import pt_toast from './lang/pt/toast.json';
import pt_pageQuestionnaire from './lang/pt/pageQuestionnaire.json';
import pt_addOn from './lang/pt/addOn.json';

import es_button from './lang/es/button.json';
import es_error from './lang/es/error.json';
import es_success from './lang/es/success.json';
import es_label from './lang/es/label.json';
import es_message from './lang/es/message.json';
import es_modal from './lang/es/modal.json';
import es_nav from './lang/es/nav.json';
import es_placeholder from './lang/es/placeholder.json';
import es_time from './lang/es/time.json';
import es_toast from './lang/es/toast.json';
import es_pageQuestionnaire from './lang/es/pageQuestionnaire.json';
import es_addOn from './lang/es/addOn.json';

import th_button from './lang/th/button.json';
import th_error from './lang/th/error.json';
import th_success from './lang/th/success.json';
import th_label from './lang/th/label.json';
import th_message from './lang/th/message.json';
import th_modal from './lang/th/modal.json';
import th_nav from './lang/th/nav.json';
import th_placeholder from './lang/th/placeholder.json';
import th_time from './lang/th/time.json';
import th_toast from './lang/th/toast.json';
import th_pageQuestionnaire from './lang/th/pageQuestionnaire.json';
import th_addOn from './lang/th/addOn.json';

import cmn_button from './lang/cmn/button.json';
import cmn_error from './lang/cmn/error.json';
import cmn_success from './lang/cmn/success.json';
import cmn_label from './lang/cmn/label.json';
import cmn_message from './lang/cmn/message.json';
import cmn_modal from './lang/cmn/modal.json';
import cmn_nav from './lang/cmn/nav.json';
import cmn_placeholder from './lang/cmn/placeholder.json';
import cmn_time from './lang/cmn/time.json';
import cmn_toast from './lang/cmn/toast.json';
import cmn_pageQuestionnaire from './lang/cmn/pageQuestionnaire.json';
import cmn_addOn from './lang/cmn/addOn.json';

import ko_button from './lang/ko/button.json';
import ko_error from './lang/ko/error.json';
import ko_success from './lang/ko/success.json';
import ko_label from './lang/ko/label.json';
import ko_message from './lang/ko/message.json';
import ko_modal from './lang/ko/modal.json';
import ko_nav from './lang/ko/nav.json';
import ko_placeholder from './lang/ko/placeholder.json';
import ko_time from './lang/ko/time.json';
import ko_toast from './lang/ko/toast.json';
import ko_pageQuestionnaire from './lang/ko/pageQuestionnaire.json';
import ko_addOn from './lang/ko/addOn.json';

import 'moment/locale/id';

// add lazy load for languages
const resources = {
	en: {
		translation: {
			lng: 'English',
			locale: 'en',
			button: en_button,
			error: en_error,
			label: en_label,
			message: en_message,
			modal: en_modal,
			nav: en_nav,
			placeholder: en_placeholder,
			time: en_time,
			toast: en_toast,
			success: en_success,
			pageQuestionnaire: en_pageQuestionnaire,
			addOn: en_addOn,
		},
	},
	id: {
		translation: {
			lng: 'Bahasa Indonesia',
			locale: 'id',
			button: id_button,
			error: id_error,
			label: id_label,
			message: id_message,
			modal: id_modal,
			nav: id_nav,
			placeholder: id_placeholder,
			time: id_time,
			toast: id_toast,
			success: id_success,
			pageQuestionnaire: id_pageQuestionnaire,
			addOn: id_addOn,
		},
	},
	pt: {
		translation: {
			lng: 'Portuguese',
			locale: 'pt',
			button: pt_button,
			error: pt_error,
			label: pt_label,
			message: pt_message,
			modal: pt_modal,
			nav: pt_nav,
			placeholder: pt_placeholder,
			time: pt_time,
			toast: pt_toast,
			success: pt_success,
			pageQuestionnaire: pt_pageQuestionnaire,
			addOn: pt_addOn,
		},
	},
	es: {
		translation: {
			lng: 'Spanish',
			locale: 'es',
			button: es_button,
			error: es_error,
			label: es_label,
			message: es_message,
			modal: es_modal,
			nav: es_nav,
			placeholder: es_placeholder,
			time: es_time,
			toast: es_toast,
			success: es_success,
			pageQuestionnaire: es_pageQuestionnaire,
			addOn: es_addOn,
		},
	},
	th: {
		translation: {
			lng: 'Thailand',
			locale: 'th',
			button: th_button,
			error: th_error,
			label: th_label,
			message: th_message,
			modal: th_modal,
			nav: th_nav,
			placeholder: th_placeholder,
			time: th_time,
			toast: th_toast,
			success: th_success,
			pageQuestionnaire: th_pageQuestionnaire,
			addOn: th_addOn,
		},
	},
	cmn: {
		translation: {
			lng: 'Mandarin',
			locale: 'cmn',
			button: cmn_button,
			error: cmn_error,
			label: cmn_label,
			message: cmn_message,
			modal: cmn_modal,
			nav: cmn_nav,
			placeholder: cmn_placeholder,
			time: cmn_time,
			toast: cmn_toast,
			success: cmn_success,
			pageQuestionnaire: cmn_pageQuestionnaire,
			addOn: cmn_addOn,
		},
	},
	ko: {
		translation: {
			lng: 'Korean',
			locale: 'ko',
			button: ko_button,
			error: ko_error,
			label: ko_label,
			message: ko_message,
			modal: ko_modal,
			nav: ko_nav,
			placeholder: ko_placeholder,
			time: ko_time,
			toast: ko_toast,
			success: ko_success,
			pageQuestionnaire: ko_pageQuestionnaire,
			addOn: ko_addOn,
		},
	},
};

i18n
	.use(initReactI18next)
	.use(HttpApi)
	.init({
		resources,
		lng: 'en',
		fallbackLng: 'en',
		debug: process.env.NODE_ENV === 'development',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;

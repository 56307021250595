import Select from './ReactSelect';
import CustomOption from './CustomOption';
import CustomMenuList from './CustomMenuList';
import { components as componentsBase } from 'react-select';

const components = {
	...componentsBase,
	Option: CustomOption,
	MenuList: CustomMenuList,
};

export * from 'react-select';

export { components };

export default Select;

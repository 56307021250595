/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { LMS_ROUTE, LMS_ROUTE_BASE } from 'routes/LmsRoutes';
import NimblyIcon from '../../../assets/icon/header-mobile-nimbly-white.svg';
import AdminIcon from '../../../assets/icon/mobile-navigation-admin.svg';
import DashboardIcon from '../../../assets/icon/mobile-navigation-dashboard.svg';
import GalleryIcon from '../../../assets/icon/sidebar/gallery-icon.svg';
import RepositoryIcon from '../../../assets/icon/sidebar/repository-icon.svg';
import IssuesIcon from '../../../assets/icon/mobile-navigation-issues.svg';
import SettingsIcon from '../../../assets/icon/mobile-navigation-settings.svg';
import NavigationItem from './NavigationItem';

class Navigation extends React.Component {
	pageIsAdmin = (_match, location) => {
		return location.pathname.indexOf('/admin/') > -1;
	};

	pageIsAnalytics = (_match, location) => {
		return location.pathname.indexOf('/analytics/') > -1;
	};

	pageIsNimblyLearn = (_match, location) => {
		return location.pathname.indexOf(`${LMS_ROUTE_BASE}/`) > -1;
	};

	render() {
		const { profile, organization, featureAccess, lmsRole } = this.props;
		const isSuperadmin = profile?.isLoaded && profile.role === 'superadmin';
		const organizationHasLms = featureAccess.features.LMS;
		const isLmsStandalone = organization?.organization?.lmsType === 'standalone';

		const { adminAccess, galleryAccess, repositoryAccess, dashboardAccess, issueTrackerAccess } = this.props;
		return (
			<Translation>
				{(t) => (
					<NavigationRoot
						ref={(node) => {
							this.root = node;
							return this.root;
						}}
					>
						{dashboardAccess ? (
							<NavigationItem
								path="/analytics/executive"
								icon={<img src={DashboardIcon} alt="icon" />}
								title={t('nav.dashboard')}
								activeClassName="active"
								isActive={this.pageIsAnalytics}
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}
						{adminAccess ? (
							<NavigationItem
								path="/reportsHub"
								icon={<img src={AdminIcon} alt="icon" />}
								title="Reports"
								activeClassName="active"
							/>
						) : null}
						{issueTrackerAccess ? (
							<NavigationItem
								path="/issues"
								icon={<img src={IssuesIcon} alt="icon" />}
								title={t('nav.issuetracker')}
								activeClassName="active"
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}
						{galleryAccess ? (
							<NavigationItem
								path="/gallery"
								icon={<img src={GalleryIcon} alt="icon" />}
								title={t('nav.gallery')}
								activeClassName="active"
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}
						{repositoryAccess ? (
							<NavigationItem
								path="/repository"
								icon={<img src={RepositoryIcon} alt="icon" />}
								title={t('nav.repository')}
								activeClassName="active"
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}
						<NavigationItem
							path={lmsRole === 'learner' ? LMS_ROUTE.COURSE_AND_SYLLABUS_CATALOG : LMS_ROUTE.DASHBOARD}
							className="nimbly-learn-icon"
							icon={<img src={NimblyIcon} alt="icon" />}
							title={t('nav.mobileNimblyLearn')}
							activeClassName="active"
							isActive={this.pageIsNimblyLearn}
							isDisabled={!organizationHasLms}
						/>
						{adminAccess ? (
							<NavigationItem
								path="/admin/sites"
								icon={<img src={AdminIcon} alt="icon" />}
								title={t('nav.manage')}
								activeClassName="active"
								isActive={this.pageIsAdmin}
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}

						{adminAccess ? (
							<NavigationItem
								path="/settings/general"
								icon={<img src={SettingsIcon} alt="icon" />}
								title={t('nav.settings')}
								activeClassName="active"
								isDisabled={organizationHasLms && isLmsStandalone}
							/>
						) : null}
						{/* {isSuperadmin ? (
							<NavigationItem
								path="/superadmin/organizations"
								icon={<img src={AdminIcon} alt="icon" />}
								title={t('nav.superadmin')}
								activeClassName="active"
							/>
						) : null} */}
					</NavigationRoot>
				)}
			</Translation>
		);
	}
}

const NavigationRoot = styled.nav`
	box-sizing: border-box;

	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;

	.nav-item.nimbly-learn-icon,
	a.nimbly-learn-icon {
		img {
			display: block;
			filter: brightness(0.5);
			height: 25px;
			width: 25px;
			padding: 2px;
		}
	}

	a.nimbly-learn-icon.active,
	.nav-item.nimbly-learn-icon.active {
		color: #535353;

		border-bottom-color: transparent;
		font-weight: normal;

		img {
			filter: brightness(0) saturate(100%) invert(34%) sepia(44%) saturate(4433%) hue-rotate(134deg) brightness(104%)
				contrast(101%);
		}
	}

	.nav-item.disabled {
		cursor: not-allowed;

		img {
			filter: brightness(0) saturate(100%) opacity(0.3);
		}
	}

	@media (min-width: 992px) {
		height: 50%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 1.875rem;
	}
`;

const connectedNavigation = connect((state) => {
	const features = state.featureAccess.features;
	const isQuizEnabled = features.LMS_QUIZ;
	const adminAccess = state.userAccess.admin.sites.all.permissions.edit || !isQuizEnabled;

	return {
		pathname: state.router.location.pathname,
		screen: state.screen,
		profile: state.firebase.profile,
		featureAccess: state.featureAccess,
		organization: state.organization,
		adminAccess,
		galleryAccess: !isQuizEnabled || (adminAccess && features.ATTACHMENT_GALLERY),
		repositoryAccess: !isQuizEnabled || (adminAccess && features.FILE_REPOSITORY),
		dashboardAccess: !isQuizEnabled || (adminAccess && features.ADVANCED_ANALYTICS),
		issueTrackerAccess: !isQuizEnabled || (adminAccess && features.ISSUE_TRACKER),
		isQuizEnabled,
		lmsRole: state.userAccess.lmsRole,
	};
})(Navigation);

export default connectedNavigation;

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga';
import { IssueFilterQuery, IssueStatusType, IssueType } from '../IssueTracker.types';
import {
	clearIssuesFilters,
	resetSelectedCustomFilter,
	setIsNewCustomFilterModalOpen,
	setIssuesFilteredDate,
	setIssuesFilters,
	setIssuesTempFilteredDate,
	setIssuesTempFilters,
	setSelectedCustomFilter,
} from 'reducers/issues/issues.action';
import { BaseSelectOptionType } from 'components/global/Select/utils/optionsMapper';
import queryString from 'query-string';
import moment, { Moment } from 'moment';
import { IssueStatus } from '@nimbly-technologies/nimbly-common';
import Monitoring from 'utils/monitoring/Monitoring';
import { IssueFilters } from 'reducers/issues/issues.reducer';
import { RootState } from 'store/rootReducers';
import { cloneDeep } from 'lodash';

const eventLogAction: { [key: string]: string } = {
	[IssueFilterQuery.QUESTIONNAIRE]: 'Issue Tracker - Filter issues by questionnaire',
	[IssueFilterQuery.USER]: 'Issue Tracker - Filter issues by user',
	[IssueFilterQuery.APPROVERS]: 'Issue Tracker - Filter issues by approvers',
	[IssueFilterQuery.DEPARTMENT]: 'Issue Tracker - Filter issues by department',
	[IssueFilterQuery.SITE]: 'Issue Tracker - Filter issues by site',
	[IssueFilterQuery.FLAG]: 'Issue Tracker - Filter issues by flag',
	[IssueFilterQuery.PRIORITY]: 'Issue Tracker - Filter issues by priority',
	[IssueFilterQuery.STATUS]: 'Issue Tracker - Filter issues by status',
	[IssueFilterQuery.SECONDARY_STATUS]: 'Issue Tracker - Filter issues by secondary status',
	[IssueFilterQuery.APPROVAL_STATUS]: 'Issue Tracker - Filter issues by approval status',
};

export const useIssueFilterSelector = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { tempFilters, tempFilteredDate } = useSelector((state: RootState) => state.issues);

	const getFilterValue = (value: { label: string; value: string }) => {
		if (!value) return null;
		return value;
	};

	const handleSelectFilter = (key: keyof IssueFilters, value: string[]) => {
		Monitoring.logEvent('handleSelectFilter', { key, value });

		const newFilters = cloneDeep(tempFilters);
		// @ts-ignore
		newFilters[key] = value;

		dispatch(setIssuesTempFilters(newFilters));

		ReactGA.event({
			category: 'Issue Tracker',
			action: eventLogAction[key],
		});
	};

	const handleSelectFilters = (key: keyof IssueFilters, options: BaseSelectOptionType[] = []) => {
		Monitoring.logEvent('handleSelectFilters', { key, options });
		const values = options?.map((option) => option.value) ?? [];
		handleSelectFilter(key, values);
	};

	const handleSelectIssueType = (options: BaseSelectOptionType[], statusFilters: IssueStatus[]) => {
		const values = options.map((option) => option.value);
		const newFilters = cloneDeep(tempFilters);
		newFilters[IssueFilterQuery.ISSUE_TYPE] = values as IssueType[];

		if (values.length && !values.includes(IssueType.CUSTOMER_FEEDBACK)) {
			newFilters[IssueFilterQuery.CF_QUESTIONNAIRE] = [];
		}

		if (values.length === 1 && values.includes(IssueType.CUSTOMER_FEEDBACK)) {
			const disabledStatuses: IssueStatus[] = [IssueStatusType.IN_REVIEW, IssueStatusType.BLOCKED];
			const newStatusFilter = statusFilters.filter((status: IssueStatus) => !disabledStatuses.includes(status));
			newFilters[IssueFilterQuery.STATUS] = newStatusFilter;
			newFilters[IssueFilterQuery.QUESTIONNAIRE] = [];
		}

		dispatch(setIssuesTempFilters(newFilters));
	};

	const handleDateChange = (startDate: Moment | null, endDate: Moment | null) => {
		dispatch(setIssuesTempFilteredDate(null, null));
		dispatch(setIssuesTempFilteredDate(startDate, endDate));
	};

	const handleApplyFilters = () => {
		const query = (tempFilters as unknown) as { [key: string]: string | string[] };

		if (tempFilteredDate.startDate && tempFilteredDate.endDate) {
			query.qStartDate = moment(tempFilteredDate.startDate).format('YYYY-MM-DD');
			query.qEndDate = moment(tempFilteredDate.endDate).format('YYYY-MM-DD');
		}

		const searchString = queryString.stringify(query);
		dispatch(setIssuesFilters(tempFilters));
		dispatch(setIssuesFilteredDate(tempFilteredDate.startDate, tempFilteredDate.endDate));
		dispatch(setSelectedCustomFilter(null));

		history.push(`/issues?${searchString}`);
	};

	const handleSaveFilters = async () => {
		dispatch(setIsNewCustomFilterModalOpen(true));
	};

	const handleResetFilters = async () => {
		dispatch(clearIssuesFilters());
		dispatch(resetSelectedCustomFilter());
		history.push(`/issues`);
	};

	return {
		getFilterValue,
		handleSelectFilter,
		handleSelectFilters,
		handleSelectIssueType,
		handleDateChange,
		handleApplyFilters,
		handleSaveFilters,
		handleResetFilters,
	};
};

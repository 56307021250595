import React, { useMemo } from 'react';
import SelectBase, { OptionTypeBase } from 'react-select';

import { Props as SelectPropsBase } from 'react-select/src/Select';
import { Props } from 'react-select/src/stateManager';
import CustomMenuList from './CustomMenuList';
import CustomOption from './CustomOption';
import './ReactSelect.css';

type SelectProps<OptionType extends OptionTypeBase> = Props<OptionType> & SelectPropsBase<OptionType>;

const Select = <OptionType extends OptionTypeBase>(props: SelectProps<OptionType>) => {
	const components = useMemo(
		() => ({
			Option: CustomOption,
			MenuList: CustomMenuList,
			...props.components,
		}),
		[props.components],
	);

	return <SelectBase {...props} components={components} classNamePrefix="react-select-custom-select" />;
};

export default Select;

import { SecondaryIssueStatus } from '@nimbly-technologies/nimbly-common';
import { IssueSecondaryStatusType, IssueStatusType, IssueType } from '../IssueTracker.types';
import useIssueDataOptions from './useIssueDataOptions';
import { ApprovalDetailsI } from 'reducers/issues/issues.reducer';

const useIssueFilterDataUtils = () => {
	const { statusMap, secondaryStatusMap, statusOptions, statusOptionsCF } = useIssueDataOptions();

	const getStatusOptions = (sourceType: string[]) => {
		if (sourceType.length === 1 && sourceType.includes(IssueType.CUSTOMER_FEEDBACK)) {
			const optionalStatuses = [IssueStatusType.IN_REVIEW, IssueStatusType.BLOCKED];

			return Object.values(statusMap).map((option) => {
				const value = option.value as IssueStatusType;
				const isDisabled = optionalStatuses.includes(value);

				return { ...option, isDisabled };
			});
		}

		return Object.values(statusMap);
	};

	const getSecondaryStatusOptions = (selectedPrimaryStatus: string[], selectedSecondaryStatus: string[]) => {
		if (selectedPrimaryStatus.length === 0) {
			return Object.values(secondaryStatusMap);
		}

		return Object.values(secondaryStatusMap).map((option) => {
			let isDisabled;
			if (option.value === IssueSecondaryStatusType.OVERDUE) {
				isDisabled = selectedPrimaryStatus.length === 1 && selectedPrimaryStatus.includes(IssueStatusType.RESOLVED);
			} else {
				isDisabled = !selectedPrimaryStatus.includes(IssueStatusType.RESOLVED);
			}

			return {
				...option,
				isDisabled: isDisabled && !selectedSecondaryStatus.includes(option.value as SecondaryIssueStatus),
			};
		});
	};

	const getStatusOptionsBasedOnIssueType = (selectedIssueType: string, selectedIssueStatus: string) => {
		if (selectedIssueType === IssueType.CUSTOMER_FEEDBACK) {
			return statusOptionsCF[selectedIssueStatus];
		}

		return statusOptions[selectedIssueStatus];
	};

	return { getStatusOptions, getSecondaryStatusOptions, getStatusOptionsBasedOnIssueType };
};

export default useIssueFilterDataUtils;

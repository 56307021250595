/** Data Types */
import * as actions from './issues.action';
import * as types from './issues.actionTypes';
import { ActionType } from 'typesafe-actions';
import {
	ExtendedIssue,
	IssueStatus,
	IssueTrackerFilter,
	IssueType,
	SecondaryIssueStatus,
	Severity,
} from '@nimbly-technologies/nimbly-common';
import update from 'immutability-helper';

/** Functions & Utilities */
import { Moment } from 'moment';
import { IssuePriorityType } from 'components/issues/IssueTracker.types';

/** Usable functions for other components */
export type IssuesAction = ActionType<typeof actions>;
export type IssueWithIndex = ExtendedIssue & { index: number } & { memberDetails: MemberDetails };

export interface MemberDetails {
	[key: string]: {
		name: string;
		email: string;
		status: string;
	};
}
export enum SORTING_ORDER {
	NEWEST = 'Newest',
	OLDEST = 'Oldest',
}
export interface IssueFilters {
	qIssueType: IssueType[];
	qQuestionnaire: string[];
	qCFQuestionnaireID: string[];
	qUser: string[];
	qApprovers: string[];
	qSite: string[];
	qDepartment: string[];
	qFlag: Severity[];
	qPriorityV2: IssuePriorityType[];
	qStatus: IssueStatus[];
	qSecStatus: SecondaryIssueStatus[];
	qApprovalStatus: string[];
}

export interface ApprovalDetailsI {
	_id: string;
	issueID: string;
	requesterID: string;
	approvalType: string;
	approvers: {
		isCurrentApprover: boolean;
		userID: string;
		status: string;
		respondedAt: string;
		timeTaken: number;
		comments: string;
		approverName: string;
	}[];
	comments: string;
	organizationID: string;
	isOutdated: boolean;
	status: string;
	createdAt: string;
	updatedAt: string;
	requesterName: string;
	approvalRequest: boolean;
	cancelRequestVisible: boolean;
	isApprover: boolean;
}

export interface ApproversListI {
	displayName: string;
	email: string;
	_id: string;
}

export interface IssueReducerType {
	issuesData: Array<ExtendedIssue> | null;
	selectedIssues: IssueWithIndex[];
	selectedIssue: IssueWithIndex | null;
	selectedPhotos: [];

	/** Filter query information */
	filters: IssueFilters;
	tempFilters: IssueFilters;
	filteredQuestion: string | undefined;
	filteredStartDate: Moment | null;
	filteredEndDate: Moment | null;
	tempFilteredDate: {
		startDate: Moment | null;
		endDate: Moment | null;
	};
	sortBy: string;
	sortDirection: 'asc' | 'desc';
	queryLimit: number;
	filterQuery: string;
	page: number;
	totalPages: number;
	isPageEnd: boolean;
	customFilters: IssueTrackerFilter[];
	selectedCustomFilter: { key: string; name: string } | null;
	isNewCustomFilterModalOpen: boolean;

	/** Page sub-components configuration */
	isSingleLoading: { [index: number]: boolean };
	isLoading: boolean;
	isLoadingMoreIssues: boolean;
	processedIssueKeys: [];
	modalVisible: boolean;
	modalCreateIssueVisible: boolean;
	modalMemberVisible: boolean;
	isFilterOpen: boolean;

	/** Issue Create V2 **/
	isCreationLoading: boolean;
	isCreationSuccess: boolean;
	isCreationFailed: boolean;

	// Comment
	activityTab: string;
	activityOrder: string;

	// Approval Details
	approvalDetails: ApprovalDetailsI;
	approversList: ApproversListI[];
}

const initialState: IssueReducerType = {
	modalVisible: false,
	modalCreateIssueVisible: false,
	modalMemberVisible: false,
	selectedPhotos: [],
	selectedIssues: [],
	selectedIssue: null,

	/** Filter query information */
	filters: {
		qIssueType: [],
		qQuestionnaire: [],
		qCFQuestionnaireID: [],
		qUser: [],
		qApprovers: [],
		qDepartment: [],
		qSite: [],
		qFlag: [],
		qPriorityV2: [],
		qStatus: [],
		qSecStatus: [],
		qApprovalStatus: [],
	},
	tempFilters: {
		qIssueType: [],
		qQuestionnaire: [],
		qCFQuestionnaireID: [],
		qUser: [],
		qApprovers: [],
		qDepartment: [],
		qSite: [],
		qFlag: [],
		qPriorityV2: [],
		qStatus: [],
		qSecStatus: [],
		qApprovalStatus: [],
	},
	filteredQuestion: '',
	filteredStartDate: null,
	filteredEndDate: null,
	tempFilteredDate: {
		startDate: null,
		endDate: null,
	},
	sortBy: 'createdAt',
	sortDirection: 'desc',
	page: 1,
	totalPages: 0,
	queryLimit: 25,
	filterQuery: '',
	isPageEnd: false,
	customFilters: [],
	selectedCustomFilter: { key: 'all', name: 'All' },
	isNewCustomFilterModalOpen: false,

	/** Page configuration */
	issuesData: [],
	isLoading: false,
	isLoadingMoreIssues: false,
	isSingleLoading: {},
	processedIssueKeys: [],
	isFilterOpen: false,

	/** Create Issue V2 Request */
	isCreationLoading: false,
	isCreationSuccess: false,
	isCreationFailed: false,
	activityTab: 'all',
	activityOrder: SORTING_ORDER.NEWEST,

	// Approval details
	approvalDetails: {
		_id: '',
		issueID: '',
		requesterID: '',
		approvalType: '',
		approvers: [],
		comments: '',
		organizationID: '',
		isOutdated: false,
		status: '',
		createdAt: '',
		updatedAt: '',
		requesterName: '',
		approvalRequest: false,
		cancelRequestVisible: false,
		isApprover: false,
	},
	approversList: [],
};

// eslint-disable-next-line complexity
export function issuesReducer(state = initialState, action: IssuesAction): IssueReducerType {
	switch (action.type) {
		case types.FETCH_ISSUES_LIST:
			return {
				...state,
				issuesData: action.payload.data,
			};
		case types.SET_IS_SINGLE_LOADING:
			return {
				...state,
				isSingleLoading: action.payload.value,
			};
		case types.SET_IS_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case types.SET_STOP_LOADING:
			return {
				...state,
				isLoading: false,
			};
		case types.SHOW_MODAL:
			return {
				...state,
				modalVisible: true,
				selectedPhotos: action.payload.photos,
			};
		case types.SHOW_CREATE_ISSUE_MODAL:
			return {
				...state,
				modalCreateIssueVisible: action.payload.status,
				isCreationSuccess: false,
				isCreationLoading: false,
				isCreationFailed: false,
			};
		case types.SHOW_MODAL_MEMBER: {
			return {
				...state,
				modalMemberVisible: action.payload.status,
			};
		}
		case types.DISMISS_MODAL:
			return {
				...state,
				modalVisible: false,
				selectedPhotos: [],
			};
		case types.SELECT_ISSUE:
			return {
				...state,
				selectedIssue: action.payload.issue,
			};
		case types.SELECT_ISSUES:
			return {
				...state,
				selectedIssues: action.payload.issues,
			};
		case types.READ_ISSUE:
			if (!state.selectedIssue) {
				return { ...state };
			}
			return update(state, {
				issuesData: { [state.selectedIssue!.index]: { unreadStatus: { $set: false } } },
				selectedIssue: { unreadStatus: { $set: false } },
			});
		case types.READ_OR_UNREAD_ISSUES: {
			if (!state.issuesData || !state.selectedIssues.length) {
				return { ...state };
			}
			const issuesData = [...state.issuesData];
			const selectedIssues = state.selectedIssues.map((issue) => {
				const updatedIssue = { ...issue, unreadStatus: action.payload.type === 'unread' };
				issuesData[issue.index] = updatedIssue;
				return updatedIssue;
			});
			return { ...state, issuesData, selectedIssues };
		}
		case types.SET_SEVERITY:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, severity: action.payload.severity },
			};
		case types.SET_STATUS:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, status: action.payload.status },
			};
		case types.SET_PRIORITY_V2:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, priorityV2: { key: action.payload.priorityKey, name: '' } },
			};
		case types.SET_ASSIGNEE:
			return {
				...state,
				selectedIssue: {
					...state.selectedIssue!,
					assignedTo: {
						key: action.payload.userID,
						name: action.payload.name,
					},
				},
			};
		case types.SET_DEPARTMENT:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, assignedDepartments: [action.payload.departments] },
			};
		case types.SET_REPORTER_DEPARTMENT:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, department: action.payload.department },
			};
		case types.SET_DUE_DATE:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, dueDate: action.payload.date },
			};
		case types.SET_PRIORITY:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, priority: action.payload.priority },
			};
		case types.SET_MEMBERS:
			return {
				...state,
				selectedIssue: { ...state.selectedIssue!, members: action.payload.newMembers },
			};
		case types.SET_MEMBER_DETAILS:
			return {
				...state,
				selectedIssue: {
					...state.selectedIssue!,
					memberDetails: action.payload.memberDetails,
				},
			};
		case types.SET_FILTERED_QUESTION:
			return {
				...state,
				filteredQuestion: action.payload.text,
				page: 1,
			};
		case types.SET_FILTERED_DATE:
			return {
				...state,
				filteredStartDate: action.payload.startDate,
				filteredEndDate: action.payload.endDate,
				page: 1,
			};
		case types.SET_TEMP_FILTERED_DATE:
			return {
				...state,
				tempFilteredDate: {
					startDate: action.payload.startDate,
					endDate: action.payload.endDate,
				},
			};
		case types.SET_ISSUES_FILTERS:
			return {
				...state,
				filters: {
					...state.filters,
					...action.payload.filters,
				},
				tempFilters: {
					...state.filters,
					...action.payload.filters,
				},
				selectedCustomFilter: null,
			};
		case types.SET_ISSUES_TEMP_FILTERS:
			return {
				...state,
				tempFilters: {
					...state.tempFilters,
					...action.payload.tempFilters,
				},
			};
		case types.SET_SELECTED_CUSTOM_FILTER:
			return {
				...state,
				selectedCustomFilter: action.payload,
			};
		case types.RESET_SELECTED_CUSTOM_FILTER:
			return {
				...state,
				selectedCustomFilter: initialState.selectedCustomFilter,
			};
		case types.SET_CUSTOM_FILTERS:
			return {
				...state,
				customFilters: action.payload.customFilters,
			};
		case types.CLEAR_ISSUES_FILTERS:
			return {
				...state,
				filters: initialState.filters,
				tempFilters: initialState.tempFilters,
				filteredStartDate: initialState.filteredStartDate,
				filteredEndDate: initialState.filteredEndDate,
				tempFilteredDate: initialState.tempFilteredDate,
			};
		case types.SET_SORT:
			return {
				...state,
				sortBy: action.payload.sortBy,
				sortDirection: action.payload.sortDirection,
				page: 1,
			};
		case types.SCROLL_LIST:
			return {
				...state,
				page: state.page + 1,
			};
		case types.SET_PROCESSED_ISSUE:
			return {
				...state,
				processedIssueKeys: action.payload.processedIssueKeys,
			};
		case types.SET_IS_FILTER_OPEN:
			return {
				...state,
				isFilterOpen: action.payload.isFilterOpen,
			};
		case types.SET_IS_NEW_CUSTOM_FILTER_MODAL_OPEN:
			return {
				...state,
				isNewCustomFilterModalOpen: action.payload.isNewCustomFilterModalOpen,
			};
		case types.SET_FILTER_QUERY:
			return {
				...state,
				filterQuery: action.payload.query,
			};
		case types.FETCH_ISSUE_BATCH_REQUEST:
			return {
				...state,
				isLoading: !action.payload.loadMore,
				isLoadingMoreIssues: action.payload.loadMore || false,
			};
		case types.FETCH_ISSUE_BATCH_SUCCESS:
			return {
				...state,
				issuesData: action.payload.issues,
				selectedIssue: action.payload.selectedIssue === undefined ? state.selectedIssue : action.payload.selectedIssue,
				isPageEnd: action.payload.isPageEnd,
				isLoading: false,
				isLoadingMoreIssues: false,
			};
		// @ts-expect-error
		case types.SET_ISSUES_DATA: {
			// @ts-expect-error
			const { issues = [], page, isPageEnd = false } = action.payload;

			const currentIssues = state.issuesData ?? [];

			const data = page === 1 ? issues : [...currentIssues, ...issues];
			return {
				...state,
				issuesData: data,
				isLoading: false,
				isPageEnd,
			};
		}
		case types.FETCH_ISSUE_BATCH_FAILURE:
			return {
				...state,
				isLoading: false,
				isLoadingMoreIssues: false,
			};
		case types.FETCH_ISSUE_CUSTOM_FILTERS_REQUEST:
			return state;
		case types.FETCH_ISSUE_CUSTOM_FILTERS_SUCCESS:
			return {
				...state,
				customFilters: action.payload.customFilters,
			};
		case types.FETCH_ISSUE_CUSTOM_FILTERS_FAILURE:
			return state;
		case types.UPDATE_ISSUE_SINGLE_REQUEST:
			return {
				...state,
				isSingleLoading:
					state.selectedIssue && state.selectedIssue.index > -1
						? { ...state.isSingleLoading, [state.selectedIssue.index]: true }
						: { ...state.isSingleLoading },
			};
		case types.UPDATE_ISSUE_SINGLE_SUCCESS:
			return {
				...state,
				issuesData: action.payload.issues,
				selectedIssue: action.payload.issue,
				isSingleLoading:
					state.selectedIssue && state.selectedIssue.index > -1
						? { ...state.isSingleLoading, [state.selectedIssue.index]: false }
						: { ...state.isSingleLoading },
			};
		case types.UPDATE_ISSUE_SINGLE_FAILURE:
			return {
				...state,
				isLoading: false,
				isSingleLoading:
					state.selectedIssue && state.selectedIssue.index > -1
						? { ...state.isSingleLoading, [state.selectedIssue.index]: false }
						: { ...state.isSingleLoading },
			};
		case types.QUICK_RESOLVE_ISSUE_REQUEST:
			return {
				...state,
				isSingleLoading:
					state.selectedIssue && state.selectedIssue.index > -1
						? { ...state.isSingleLoading, [state.selectedIssue.index]: true }
						: { ...state.isSingleLoading },
			};
		case types.QUICK_RESOLVE_ISSUE_SUCCESS:
			return update(state, {
				issuesData: { [state.selectedIssue!.index]: { $set: action.payload.issue } },
				selectedIssue: { $set: action.payload.issue },
				isSingleLoading: {
					$set:
						state.selectedIssue && state.selectedIssue.index > -1
							? { ...state.isSingleLoading, [state.selectedIssue.index]: false }
							: { ...state.isSingleLoading },
				},
			});
		case types.QUICK_RESOLVE_ISSUE_FAILURE:
			return {
				...state,
				isLoading: false,
				isSingleLoading:
					state.selectedIssue && state.selectedIssue.index > -1
						? { ...state.isSingleLoading, [state.selectedIssue.index]: false }
						: { ...state.isSingleLoading },
			};
		case types.CREATE_ISSUE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case types.CREATE_ISSUE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case types.CREATE_ISSUE_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case types.CREATE_ISSUE_V2_REQUEST:
			return {
				...state,
				isCreationLoading: true,
				isCreationSuccess: false,
				isCreationFailed: false,
			};
		case types.CREATE_ISSUE_V2_SUCCESS:
			return {
				...state,
				isCreationLoading: false,
				isCreationSuccess: true,
				isCreationFailed: false,
				issuesData: action.payload.issues,
				selectedIssue: action.payload.selectedIssue,
			};
		case types.CREATE_ISSUE_V2_FAILURE:
			return {
				...state,
				isCreationLoading: false,
				isCreationSuccess: false,
				isCreationFailed: true,
			};
		case types.SET_ACTIVITY_ORDER:
			return {
				...state,
				activityOrder: action.payload.order,
			};
		case types.SET_ACTIVITY_TAB:
			return {
				...state,
				activityTab: action.payload.tab,
			};
		case types.GET_APPROVAL_DETAILS_SUCCESS:
			return {
				...state,
				approvalDetails: action.payload,
			};
		case types.FETCH_APPROVER_LIST_SUCCESS:
			return {
				...state,
				approversList: action.payload,
			};
		default:
			return state;
	}
}

import {
	CreateIssueMessageRequest,
	CreateIssueTrackerFilterRequest,
	IssueTrackerFilter,
	UpdateIssueRequest,
	UpdateIssueTrackerFilterRequest,
} from '@nimbly-technologies/nimbly-common';
import {
	apiURL,
	NIMBLY_API_ISSUES_BULK_EDIT,
	NIMBLY_API_ISSUES_BULK_MESSAGE,
	NIMBLY_API_ISSUES_CUSTOM_FILTER,
} from 'config/baseURL';
import API from 'helpers/api';
import { toast } from 'react-toastify';
import { ApprovalDetailsI, ApproversListI, IssueReducerType } from 'reducers/issues/issues.reducer';

export type BulkEditIssuesData = Pick<
	UpdateIssueRequest,
	'status' | 'priorityV2' | 'severity' | 'dueDate' | 'assignedDepartments' | 'assignedTo'
>;

export type BulkEditIssuesPayloadData = Partial<BulkEditIssuesData>;

export type bulkIssueMessageType = Pick<
	CreateIssueMessageRequest,
	'createdAt' | 'createdBy' | 'createdByName' | 'message' | 'messageType'
>;

export const bulkEditIssues = async (issueIDs: string[], changedValues: BulkEditIssuesPayloadData) => {
	const url = NIMBLY_API_ISSUES_BULK_EDIT;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...changedValues,
			triggerNotification: true,
		},
	};
	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const bulkSubmitIssueMesage = async (issueIDs: string[], data: bulkIssueMessageType) => {
	const url = NIMBLY_API_ISSUES_BULK_MESSAGE;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...data,
			triggerNotification: true,
		},
	};
	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const getIssueCustomFilter = async (): Promise<IssueTrackerFilter[]> => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const saveIssueCustomFilter = async (payload: CreateIssueTrackerFilterRequest) => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const updateIssueCustomFilter = async (filterId: string, payload: UpdateIssueTrackerFilterRequest) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const deleteIssueCustomFilter = async (filterId: string) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.delete(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const postApprovalRequest = async (
	issueId: string,
	approvalType: string,
	approvers: string[],
	optionalNote: string,
) => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/create-request`;
	const payload = {
		issueID: issueId,
		approvalType: approvalType,
		approvers: approvers,
		comments: optionalNote,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);

	if (response.status !== 200) return 'FAILED';
	const responseData = await response.json();
	return 'SUCCESS';
};

export const fetchIssueApprovalData = async (issueID: string): Promise<ApprovalDetailsI | undefined> => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/${issueID}?overview=true`;
	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const response = await fetch(url, options);

	if (response.status !== 200) toast.error(`Error Status : ${response.status}`);
	const responseData = await response.json();
	if (responseData.data) return responseData.data;
};

export const postApprovalReview = async (requestId: string, issueId: string, status: string, comments: string) => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/approve-request/${requestId}`;
	const payload = {
		issueID: issueId,
		status: status,
		comments: comments,
	};
	const options = {
		method: 'PATCH',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);
	const responseData = await response.json();

	if (response.status !== 200) {
		toast.error(`Failed to post review. Error: ${response}`);
		return responseData;
	} else {
		return responseData;
	}
};

export const patchCancelRequest = async (issueId: string) => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/cancel-request/${issueId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const response = await fetch(url, options);

	if (response.status !== 200) return 'FAILED';
	return 'SUCCESS';
};

export const fetchApproverHistoryDetails = async (issueID: string, userID: string): Promise<any> => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/${issueID}?overview=false&memberID=${userID}`;
	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const response = await fetch(url, options);

	if (response.status !== 200) toast.error(`Error Status : ${response.status}`);
	const responseData = await response.json();
	return responseData;
};

export const fetchApproversList = async (requesterID: string): Promise<ApproversListI[] | undefined> => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/approvers-list?requesterID=${requesterID}`;
	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const response = await fetch(url, options);

	if (response.status !== 200) toast.error(`Error Status : ${response.status}`);

	const responseData = await response.json();
	if (responseData) {
		return responseData;
	} else {
		toast.error(`Error : ${responseData}`);
	}
};

export const patchAddApprover = async (issueID: string, userID: string[]): Promise<any> => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/add-approver/${issueID}`;
	const payload = {
		userID: userID,
	};
	const options = {
		method: 'PATCH',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);

	if (response.status !== 200) return 'FAILED';
	return 'SUCCESS';
};

export const patchRemoveApprover = async (issueID: string, userID: string): Promise<any> => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/remove-approver/${issueID}`;
	const payload = {
		userID: [userID],
	};
	const options = {
		method: 'PATCH',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);

	if (response.status !== 200) toast.error(`Failed to remove approver. Error Status : ${response.status}`);
	return 'SUCCESS';
};

import { WebWorkerFactoryV2 } from 'core/infrastructure/webWorkersV2/utility/WebWorkerFactoryV2';
import { useState, useCallback, useRef } from 'react';

/**
 * Hook to compute a memoized value using a web worker.
 *
 * @template T - The type of the memoized value.
 * @template P - The type of the parameters for the worker function.
 * @param {() => WebWorkerFactoryV2<(...params: P) => T>} createWorkerFactory - The function to create the WebWorkerFactoryV2 instance.
 * @param {P} params - The parameters for the compute function.
 * @param {T} initialValue - The initial value for the memoized value.
 */
export const useWebWorkerValueV2 = <T, P extends any[]>(
	createWorkerFactory: () => WebWorkerFactoryV2<(...params: P) => T>,
	params: P,
	initialValue: T,
): [T, Function] => {
	const [value, setValue] = useState<T>(initialValue);
	const workerFactoryRef = useRef<WebWorkerFactoryV2<(...params: P) => T> | null>(null);

	const triggerCompute = useCallback(() => {
		if (!workerFactoryRef.current) {
			workerFactoryRef.current = createWorkerFactory();
		}

		const computeValue = async () => {
			const result = await workerFactoryRef.current!.callWorker(...params);
			setValue(result);
			workerFactoryRef.current?.terminateWorker();
		};

		computeValue();
	}, [createWorkerFactory, params]);

	return [value, triggerCompute];
};

import {
	BaseOptionObject,
	BaseSelectOptionType,
	MapOptionsDataProps,
} from 'components/global/Select/utils/optionsMapper';
import { useUserDataOptions } from 'components/permissions/utils/useUserDataOptions';
import { useUserDataVisibilityOptions } from 'components/permissions/utils/useUserDataVisibilityOptions';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaignList } from 'reducers/customerFeedback/customerFeedback.action';
import { fetchDepartments, fetchDepartmentsDataVisibility } from 'reducers/departments/departments.action';
import { getQuestionnairesByPropsAsync } from 'reducers/questionnaire/questionnaire.action';
import { siteCompactAction } from 'reducers/site/siteCompact/siteCompact.action';
import { RootState } from 'store/rootReducers';
import {
	IssueApprovalStatusType,
	IssueFlagType,
	IssuePriorityType,
	IssueSecondaryStatusType,
	IssueStatusType,
	IssueType,
} from '../IssueTracker.types';
import { useWebWorkerValueV2 } from 'core/utility/react/useWebWorkerV2';
import { createMapOptionsDataToObjectWorker } from 'core/infrastructure/webWorkersV2/selectComponentUtils/mapOptionsDataToObject.worker';
import { createMapObjectValuesToArrayWorker } from 'core/infrastructure/webWorkersV2/selectComponentUtils/mapObjectValuesToArray.worker';

const selectQuestionnaires = (state: RootState) => state.questionnaireByProps.data;
const useQuestionnaires = () => {
	const questionnaires = useSelector(selectQuestionnaires);

	const questionnaireMapParams: MapOptionsDataProps = useMemo(
		() => ({
			data: questionnaires,
			key: 'questionnaireIndexID',
			value: 'questionnaireIndexID',
			label: 'title',
		}),
		[questionnaires],
	);

	const [questionnaireMap, computeQuestionnaireMap] = useWebWorkerValueV2(
		createMapOptionsDataToObjectWorker,
		[questionnaireMapParams],
		{},
	);
	useEffect(() => {
		computeQuestionnaireMap();
	}, [questionnaires]);

	const [questionnaireOptions, computeQuestionnaireOptions] = useWebWorkerValueV2(
		createMapObjectValuesToArrayWorker,
		[questionnaireMap],
		[],
	);
	useEffect(() => {
		computeQuestionnaireOptions();
	}, [questionnaireMap]);

	return { questionnaires, questionnaireMap, questionnaireOptions };
};

const selectCampaigns = (state: RootState) => state.customerFeedbackList.campaignList;
const useCampaigns = () => {
	const campaigns = useSelector(selectCampaigns);

	const campaignMapParams: MapOptionsDataProps = useMemo(
		() => ({
			data: campaigns,
			key: 'questionnaireID',
			value: 'questionnaireID',
			label: 'title',
		}),
		[campaigns],
	);

	const [campaignMap, computeCampaignMap] = useWebWorkerValueV2(
		createMapOptionsDataToObjectWorker,
		[campaignMapParams],
		{},
	);
	useEffect(() => {
		computeCampaignMap();
	}, [campaigns]);

	const [campaignOptions, computeCampaignOptions] = useWebWorkerValueV2(
		createMapObjectValuesToArrayWorker,
		[campaignMap],
		[],
	);
	useEffect(() => {
		computeCampaignOptions();
	}, [campaignMap]);

	return { campaigns, campaignMap, campaignOptions };
};

const selectDepartments = (state: RootState) => state.departments.departments ?? {};
const useDepartments = () => {
	const departments = useSelector(selectDepartments);

	const departmentMapParams = useMemo(
		() => ({
			data: Object.values(departments ?? {}),
			key: 'departmentID',
			value: 'departmentID',
			label: 'name',
		}),
		[departments],
	);

	const [departmentMap, computeDepartmentMap] = useWebWorkerValueV2(
		createMapOptionsDataToObjectWorker,
		[departmentMapParams],
		{},
	);
	useEffect(() => {
		computeDepartmentMap();
	}, [departments]);

	const [departmentOptions, computeDepartmentOptions] = useWebWorkerValueV2(
		createMapObjectValuesToArrayWorker,
		[departmentMap],
		[],
	);
	useEffect(() => {
		computeDepartmentOptions();
	}, [departmentMap]);

	return { departments, departmentMap, departmentOptions };
};

const selectSites = (state: RootState) => state.siteCompact.data;
const useSites = () => {
	const sites = useSelector(selectSites);

	const siteMapParams: MapOptionsDataProps = useMemo(
		() => ({
			data: sites,
			key: 'siteID',
			value: 'siteID',
			label: 'name',
		}),
		[sites],
	);

	const [siteMap, computeSiteMap] = useWebWorkerValueV2(createMapOptionsDataToObjectWorker, [siteMapParams], {});
	useEffect(() => {
		computeSiteMap();
	}, [sites]);

	const [siteOptions, computeSiteOptions] = useWebWorkerValueV2(createMapObjectValuesToArrayWorker, [siteMap], []);
	useEffect(() => {
		computeSiteOptions();
	}, [siteMap]);

	return { sites, siteMap, siteOptions };
};

const selectDepartmentsDataVisibility = (state: RootState) => state.departments.departmentsDataVisibility;
const useDepartmentsDataVisibility = () => {
	const departmentsDataVisibility = useSelector(selectDepartmentsDataVisibility);

	const departmentDataVisibilityMapParams = useMemo(
		() => ({
			data: Object.values(departmentsDataVisibility ?? {}),
			key: 'departmentID',
			value: 'departmentID',
			label: 'name',
		}),
		[departmentsDataVisibility],
	);

	const [departmentDataVisibilityMap, computeDepartmentDataVisibilityMap] = useWebWorkerValueV2(
		createMapOptionsDataToObjectWorker,
		[departmentDataVisibilityMapParams],
		{},
	);
	useEffect(() => {
		computeDepartmentDataVisibilityMap();
	}, [departmentsDataVisibility]);

	const [departmentDataVisibilityOptions, computeDepartmentDataVisibilityOptions] = useWebWorkerValueV2(
		createMapObjectValuesToArrayWorker,
		[departmentDataVisibilityMap],
		[],
	);
	useEffect(() => {
		computeDepartmentDataVisibilityOptions();
	}, [departmentDataVisibilityMap]);

	return { departmentsDataVisibility, departmentDataVisibilityMap, departmentDataVisibilityOptions };
};

const useIssueDataOptions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { questionnaires, questionnaireMap, questionnaireOptions } = useQuestionnaires();
	const { campaigns, campaignMap: surveyMap, campaignOptions: surveyOptions } = useCampaigns();
	const { departments, departmentMap, departmentOptions } = useDepartments();
	const { sites, siteMap, siteOptions } = useSites();
	const { userMap, userOptions } = useUserDataOptions();
	const { userDataVisibilityMap, userDataVisibilityOptions } = useUserDataVisibilityOptions();
	const {
		departmentsDataVisibility,
		departmentDataVisibilityMap,
		departmentDataVisibilityOptions,
	} = useDepartmentsDataVisibility();

	const isSitesLoading = useSelector((state: RootState) => state.siteCompact.isLoading);
	useEffect(() => {
		if (!sites.length && !isSitesLoading) {
			dispatch(siteCompactAction.request({}));
		}
	}, [sites.length, isSitesLoading]);

	const isQuestionnairesLoading = useSelector((state: RootState) => state.questionnaireByProps.isLoading);
	useEffect(() => {
		if (!questionnaires.length && !isQuestionnairesLoading) {
			dispatch(getQuestionnairesByPropsAsync.request());
		}
	}, [questionnaires.length, isQuestionnairesLoading]);

	const isCampaignsLoading = useSelector((state: RootState) => state.customerFeedbackList.isLoading);
	useEffect(() => {
		if (!campaigns.length && !isCampaignsLoading) {
			dispatch(fetchCampaignList.request());
		}
	}, [campaigns.length, isCampaignsLoading]);

	const isDepartmentsLoading = useSelector((state: RootState) => state.departments.isLoadingDepartments);
	useEffect(() => {
		if (!Object.keys(departments).length && !isDepartmentsLoading) {
			dispatch(fetchDepartments.request());
		}
	}, [departments, isDepartmentsLoading]);

	useEffect(() => {
		dispatch(fetchDepartmentsDataVisibility.request());
	}, []);

	const issueTypeMap: { [key: string]: BaseSelectOptionType } = {
		[IssueType.MANUAL_ADDITION]: {
			value: IssueType.MANUAL_ADDITION,
			label: t('label.issuePage.type.manual-addition'),
		},
		[IssueType.REPORT]: {
			value: IssueType.REPORT,
			label: t('label.issuePage.type.report'),
		},
		[IssueType.CUSTOMER_FEEDBACK]: {
			value: IssueType.CUSTOMER_FEEDBACK,
			label: t('label.issuePage.type.customer-feedback'),
		},
	};

	const flagMap: BaseOptionObject = {
		[IssueFlagType.RED]: {
			value: IssueFlagType.RED,
			label: t('label.dashboard.red'),
		},
		[IssueFlagType.YELLOW]: {
			value: IssueFlagType.YELLOW,
			label: t('label.dashboard.yellow'),
		},
	};

	const priorityMap: BaseOptionObject = {
		[IssuePriorityType.LOW]: {
			value: IssuePriorityType.LOW,
			label: t('label.issuePage.low'),
		},
		[IssuePriorityType.MEDIUM]: {
			value: IssuePriorityType.MEDIUM,
			label: t('label.issuePage.medium'),
		},
		[IssuePriorityType.HIGH]: {
			value: IssuePriorityType.HIGH,
			label: t('label.issuePage.high'),
		},
	};

	const statusMap: BaseOptionObject<IssueStatusType> = {
		[IssueStatusType.OPEN]: {
			value: IssueStatusType.OPEN,
			label: t('label.issuePage.status.open'),
			sublabel: t('label.issuePage.status.openSublabel'),
		},
		[IssueStatusType.IN_PROGRESS]: {
			value: IssueStatusType.IN_PROGRESS,
			label: t('label.issuePage.status.in-progress'),
			sublabel: t('label.issuePage.status.inProgressSublabel'),
		},
		[IssueStatusType.BLOCKED]: {
			value: IssueStatusType.BLOCKED,
			label: t('label.issuePage.status.blocked'),
			sublabel: t('label.issuePage.status.blockedSublabel'),
		},
		[IssueStatusType.IN_REVIEW]: {
			value: IssueStatusType.IN_REVIEW,
			label: t('label.issuePage.status.in-review'),
			sublabel: t('label.issuePage.status.inReviewSublabel'),
		},
		[IssueStatusType.RESOLVED]: {
			value: IssueStatusType.RESOLVED,
			label: t('label.issuePage.status.resolved'),
			sublabel: t('label.issuePage.status.resolvedSublabel'),
		},
	};

	const resolvedStatusMap: BaseOptionObject = {
		[IssueSecondaryStatusType.ON_TIME]: {
			label: t('label.issuePage.status.resolvedOnTime'),
			value: IssueStatusType.RESOLVED,
		},
		[IssueSecondaryStatusType.BEHIND_TIME]: {
			label: t('label.issuePage.status.resolvedBehindTime'),
			value: IssueStatusType.RESOLVED,
		},
	};

	const secondaryStatusMap: BaseOptionObject = {
		[IssueSecondaryStatusType.OVERDUE]: {
			value: IssueSecondaryStatusType.OVERDUE,
			label: t('label.issuePage.secondaryStatus.overdue'),
		},
		[IssueSecondaryStatusType.BEHIND_TIME]: {
			value: IssueSecondaryStatusType.BEHIND_TIME,
			label: t('label.issuePage.secondaryStatus.behind-time'),
		},
		[IssueSecondaryStatusType.ON_TIME]: {
			value: IssueSecondaryStatusType.ON_TIME,
			label: t('label.issuePage.secondaryStatus.on-time'),
		},
	};

	const approvalStatusMap: BaseOptionObject = {
		[IssueApprovalStatusType.APPROVED]: {
			value: IssueApprovalStatusType.APPROVED,
			label: 'Approved',
		},
		[IssueApprovalStatusType.REJECTED]: {
			value: IssueApprovalStatusType.REJECTED,
			label: 'Rejected',
		},
		[IssueApprovalStatusType.PENDING]: {
			value: IssueApprovalStatusType.PENDING,
			label: 'Pending',
		},
	};

	const statusOptions: { [key: string]: BaseSelectOptionType[] } = {
		[IssueStatusType.OPEN]: [statusMap[IssueStatusType.IN_PROGRESS]],
		[IssueStatusType.IN_PROGRESS]: [statusMap[IssueStatusType.RESOLVED], statusMap[IssueStatusType.BLOCKED]],
		[IssueStatusType.IN_REVIEW]: [],
		[IssueStatusType.BLOCKED]: [statusMap[IssueStatusType.OPEN]],
		[IssueStatusType.RESOLVED]: [statusMap[IssueStatusType.OPEN]],
	};

	const statusOptionsCF: { [key: string]: BaseSelectOptionType[] } = {
		[IssueStatusType.OPEN]: [statusMap[IssueStatusType.IN_PROGRESS]],
		[IssueStatusType.IN_PROGRESS]: [statusMap[IssueStatusType.RESOLVED]],
		[IssueStatusType.RESOLVED]: [statusMap[IssueStatusType.OPEN]],
	};

	return {
		questionnaireMap,
		surveyMap,
		userMap,
		departmentMap,
		siteMap,
		issueTypeMap,
		flagMap,
		priorityMap,
		statusMap,
		resolvedStatusMap,
		secondaryStatusMap,
		approvalStatusMap,
		userDataVisibilityMap,
		departmentDataVisibilityMap,

		questionnaireOptions,
		surveyOptions,
		userOptions,
		departmentOptions,
		siteOptions,
		statusOptions,
		statusOptionsCF,
		userDataVisibilityOptions,
		departmentDataVisibilityOptions,
	};
};

export default useIssueDataOptions;

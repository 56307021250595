import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './reportsHub.actionTypes';
import moment from 'moment';
import { ReportsTableData, ReportCountDetails } from 'components/reportshub/ReportHub.types';
import { SavedFiltersI, ReportHubFilterI } from './reportsHub.types';

export const setFilterDates = (startDate: moment.Moment | null, endDate: moment.Moment | null) =>
	action(types.SET_FILTER_DATE, { startDate, endDate });

export const setTableType = (type: string) => action(types.SET_TABLE_TYPE, type);

export const setPageIndex = (page: number) => action(types.SET_PAGE_INDEX, page);

export const setPageSort = (sortDirections: string, sortFields: string) =>
	action(types.SET_PAGE_SORT, { sortDirections, sortFields });

export const setSingleSelect = (key: string, value: string) => action(types.SET_SINGLE_SELECT, { key, value });

export const setMultiSelect = (key: string, value: string[]) => action(types.SET_MULTI_SELECT, { key, value });

export const setRadioValue = (key: string, value: boolean) => action(types.SET_RADIO_VALUE, { key, value });

export const setReportType = (key: string, value: string) => action(types.SET_REPORT_TYPE, { key, value });

export const resetReportCount = () => action(types.RESET_REPORT_COUNT);

export const resetFilters = () => action(types.RESET_FILTERS);

export const setLimitSpec = (showLimitSpec: boolean) => action(types.SET_LIMIT_SPEC, showLimitSpec);

export const setReportCountSuccess = (isSuccess: boolean) => action(types.SET_REPORT_COUNT_SUCCESS, isSuccess);

export const setIsSavedFilter = (isSavedFilter: boolean) => action(types.SET_IS_SAVED_FILTER, isSavedFilter);

export const setCustomFilterModal = (isNewCustomFilterModalOpen: boolean) =>
	action(types.SET_CUSTOM_FILTER_MODAL, isNewCustomFilterModalOpen);

export const setCustomFilter = (customFilter: ReportHubFilterI) => action(types.SET_CUSTOM_FILTER, customFilter);

export const setIsFilterOpen = (isFilterOpen: boolean) => action(types.SET_IS_FILTER_OPEN, isFilterOpen);

export const fetchReportsHubTableAsync = createAsyncAction(
	types.FETCH_REPORTSHUB_TABLE_REQUEST,
	types.FETCH_REPORTSHUB_TABLE_SUCCESS,
	types.FETCH_REPORTSHUB_TABLE_FAILURE,
)<undefined, ReportsTableData, string>();

export const fetchReportsCountAsync = createAsyncAction(
	types.FETCH_REPORTSCOUNT_REQUEST,
	types.FETCH_REPORTSCOUNT_SUCCESS,
	types.FETCH_REPORTSCOUNT_FAILURE,
)<undefined, ReportCountDetails, string>();

export const fetchReportsFilterAsync = createAsyncAction(
	types.FETCH_REPORTS_FILTERS_REQUEST,
	types.FETCH_REPORTS_FILTERS_SUCCESS,
	types.FETCH_REPORTS_FILTERS_FAILURE,
)<undefined, SavedFiltersI[], string>();

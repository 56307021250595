import { mapObjectValuesToArray } from 'components/issues/utils/mapObjectValueToArray';
import { WebWorkerFactoryV2 } from '../utility/WebWorkerFactoryV2';

export const mapObjectValuesToArrayWorkerScript = `(map) => {
    return Object.values(map || {});
  }
`;

export function createMapObjectValuesToArrayWorker() {
	return new WebWorkerFactoryV2<typeof mapObjectValuesToArray>(mapObjectValuesToArrayWorkerScript);
}

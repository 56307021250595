import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './adminDashboard.action';
import { AdminDashboardStore } from './adminDashboardStore';
import moment from 'moment';

export type AdminDashboardAction = ActionType<typeof actions>;

export const initialState: AdminDashboardStore = {
	isUsersCvULoading: true,
	isSitesCvULoading: true,
	isMAULoading: true,
	isTotalAvgReportsLoading: true,
	isTotalLoginDevicesLoading: true,
	isTotalActiveUsersLoading: true,
	isTotalActiveSitesLoading: true,
	isApply: false,
	filters: {
		startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
	},
	organizationId: null,
	totalUsersInfo: { activeUsers: 0, disabledUsers: 0, freshUsers: 0, totalUsers: 0 },
	totalSitesInfo: { activeSites: 0, inactiveSites: 0, totalSites: 0 },
	totalDevicesInfo: { totalDevices: 0, totalAndroid: 0, totalIos: 0, totalOthers: 0 },
	avgLoginInfo: { avgLoginsPerDay: 0 },
	usersCvUInfo: [],
	sitesCvUInfo: [],
	avgVTotalReportsInfo: [],
	mauInfo: { totalUsers: 0, MAU: [] },
	storageInfo: {
		allocationPerEntity: {
			documents: 0,
			photos: 0,
			videos: 0,
			LMS: 0,
			trash: 0,
		},
		totalAllowedStorageGB: 50,
		totalUsedStorageGB: 0,
	},
	showErrorModal: false,
	selectedOrg: null,
};

export const adminDashboardReducer = createReducer<AdminDashboardStore, AdminDashboardAction>(initialState)
	.handleAction(actions.setFilters, (state, action) => ({
		...state,
		filters: action.payload.filters,
	}))
	.handleAction(actions.setOrgId, (state, action) => ({
		...state,
		organizationId: action.payload.orgId,
	}))
	.handleAction(actions.setShowErrorModal, (state, action) => ({
		...state,
		showErrorModal: action.payload.value,
	}))
	.handleAction(actions.setSelectedOrg, (state, action) => ({
		...state,
		selectedOrg: action.payload.value,
	}))
	.handleAction(actions.setIsApply, (state, action) => ({
		...state,
		isApply: action.payload,
	}))
	.handleAction(actions.fetchTotalActiveUsersAsync.request, (state) => {
		return {
			...state,
			isTotalActiveUsersLoading: true,
		};
	})
	.handleAction(actions.fetchTotalActiveUsersAsync.success, (state, action) => {
		return {
			...state,
			totalUsersInfo: action.payload,
			isTotalActiveUsersLoading: false,
		};
	})
	.handleAction(actions.fetchTotalActiveSitesAsync.request, (state) => {
		return {
			...state,
			isTotalActiveSitesLoading: true,
		};
	})
	.handleAction(actions.fetchTotalActiveSitesAsync.success, (state, action) => {
		return {
			...state,
			totalSitesInfo: action.payload,
			isTotalActiveSitesLoading: false,
		};
	})
	.handleAction(actions.fetchTotalLoginDevicesAsync.request, (state) => {
		return {
			...state,
			isTotalLoginDevicesLoading: true,
		};
	})
	.handleAction(actions.fetchTotalLoginDevicesAsync.success, (state, action) => {
		return {
			...state,
			totalDevicesInfo: action.payload,
			isTotalLoginDevicesLoading: false,
		};
	})
	.handleAction(actions.fetchAvgLoginInfoAsync.success, (state, action) => {
		return {
			...state,
			avgLoginInfo: action.payload,
		};
	})
	.handleAction(actions.fetchUsersCvUAsync.request, (state) => {
		return {
			...state,
			isUsersCvULoading: true,
		};
	})
	.handleAction(actions.fetchUsersCvUAsync.success, (state, action) => {
		return {
			...state,
			usersCvUInfo: action.payload,
			isUsersCvULoading: false,
		};
	})
	.handleAction(actions.fetchSitesCvUAsync.request, (state) => {
		return {
			...state,
			isSitesCvULoading: true,
		};
	})
	.handleAction(actions.fetchSitesCvUAsync.success, (state, action) => {
		return {
			...state,
			sitesCvUInfo: action.payload,
			isSitesCvULoading: false,
		};
	})
	.handleAction(actions.fetchAvgVTotalReportsAsync.request, (state) => {
		return {
			...state,
			isTotalAvgReportsLoading: true,
		};
	})
	.handleAction(actions.fetchAvgVTotalReportsAsync.success, (state, action) => {
		return {
			...state,
			avgVTotalReportsInfo: action.payload,
			isTotalAvgReportsLoading: false,
		};
	})
	.handleAction(actions.fetchMAUAsync.request, (state) => {
		return {
			...state,
			isMAULoading: true,
		};
	})
	.handleAction(actions.fetchMAUAsync.success, (state, action) => {
		return {
			...state,
			mauInfo: action.payload,
			isMAULoading: false,
		};
	})
	.handleAction(actions.fetchStorageAsync.success, (state, action) => {
		return {
			...state,
			storageInfo: action.payload,
		};
	});

/**
 * Class to manage a web worker, including spawning, calling, and terminating the worker.
 */
export class WebWorkerFactoryV2<F extends (...args: any) => any> {
	private worker: Worker | null = null;

	constructor(private workerFunction: string) {}

	/**
	 * Creates a Blob URL for the worker script.
	 *
	 * @param {string} fnString - The function to be executed inside the worker.
	 * @returns {string} - The Blob URL for the worker script.
	 */
	private createWorkerBlobURL(fnString: string): string {
		const uniqueFnName = `workerFunction_${Date.now()}`;
		const blobContent = `
      const ${uniqueFnName} = ${fnString};
      onmessage = function(e) {
        postMessage(${uniqueFnName}(...e.data));
      };
    `;
		const blob = new Blob([blobContent], { type: 'application/javascript' });
		return URL.createObjectURL(blob);
	}

	/**
	 * Spawns a new worker, terminating any existing worker.
	 */
	public spawnWorker(): void {
		if (this.worker) {
			this.worker.terminate();
		}
		this.worker = new Worker(this.createWorkerBlobURL(this.workerFunction));
	}

	/**
	 * Calls the worker with the given arguments and returns a promise that resolves with the result.
	 *
	 * @param {Parameters<F>} args - Arguments to pass to the worker function.
	 * @returns {Promise<FReturn>} - Promise that resolves with the worker function's result.
	 */
	public callWorker(...args: Parameters<F>): Promise<ReturnType<F>> {
		return new Promise<ReturnType<F>>((resolve, reject) => {
			if (!this.worker) {
				this.spawnWorker();
			}

			this.worker!.onmessage = (event: MessageEvent) => {
				resolve(event.data);
			};

			this.worker!.onerror = (error: ErrorEvent) => {
				reject(error);
			};

			this.worker!.postMessage(args);
		});
	}

	/**
	 * Terminates the current worker if it exists.
	 */
	public terminateWorker(): void {
		if (this.worker) {
			this.worker.terminate();
			this.worker = null;
		}
	}
}

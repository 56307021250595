/** React utilities */
import React, { memo } from 'react';
import ReactGA from 'react-ga';
import { compose } from 'redux';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';

/** Constants */

/** Types */
import { RootState } from 'store/rootReducers';

/** Related components */

/** Assets: icons, images, buttons, etc */
import SortingArrow from '../../global/SortingArrow/SortingArrow';
import HelpIcon from '../../../assets/icon/tooltip-help.svg';
import SortIcon from '../../../assets/icon/issue-sort.svg';
import FilterIcon from '../../../assets/icon/issue-filter.svg';

/** Storage and redux */
import { setIssuesSort } from '../../../reducers/issues/issues.action';

/** Other libraries  */
import { Translation } from 'react-i18next';
import styled from 'styled-components/macro';
import FilterField from 'components/global/FilterBar/FilterField';
import useIssueDataOptions from '../utils/useIssueDataOptions';
import { useIssueFilterSelector } from '../utils/useIssueFilterSelector';
import { BaseSelectOptionType, mapValuesToOptions } from 'components/global/Select/utils/optionsMapper';
import CalendarDateRangePicker from 'components/global/CalendarPicker/CalendarDateRangePicker';
import { IssueFilterQuery, IssueType } from '../IssueTracker.types';
import useIssueFilterDataUtils from '../utils/useIssueFilterDataUtils';
import { LinkButton, OutlineButton } from 'styles/Button';
import { MdOutlineBookmarkAdd } from 'react-icons/md';

export type IssueFilterProps = {
	firebase: any;
} & DispatchProp &
	RouteComponentProps &
	StateProps;

const IssueFilter = (props: IssueFilterProps) => {
	const {
		dispatch,
		history,
		tempFilters,
		filterDataUsers,
		filterDataSites,
		filterDataDepartments,
		tempFilteredDate,
		sortBy,
		sortDirection,
		isLoading,
	} = props;

	const {
		questionnaireMap,
		questionnaireOptions,
		surveyMap,
		surveyOptions,
		userMap,
		departmentMap,
		departmentOptions,
		siteMap,
		siteOptions,
		issueTypeMap,
		flagMap,
		priorityMap,
		statusMap,
		secondaryStatusMap,
	} = useIssueDataOptions();
	const { getStatusOptions, getSecondaryStatusOptions } = useIssueFilterDataUtils();
	const {
		handleSelectFilters,
		handleSelectIssueType,
		handleDateChange,
		handleApplyFilters,
		handleSaveFilters,
		handleResetFilters,
	} = useIssueFilterSelector();

	const handleSortCreatedAt = async () => {
		const { sortBy, sortDirection } = props;
		if (sortBy === 'createdAt') {
			await dispatch(setIssuesSort('createdAt', sortDirection === 'desc' ? 'asc' : 'desc'));
		} else {
			await dispatch(setIssuesSort('createdAt', 'desc'));
		}

		ReactGA.event({
			category: 'Issue Tracker',
			action: 'Issue Tracker - Filter issues by creation date',
		});
	};

	const handleSortDueDate = async () => {
		const { sortBy, sortDirection } = props;
		if (sortBy === 'dueDate') {
			await dispatch(setIssuesSort('dueDate', sortDirection === 'desc' ? 'asc' : 'desc'));
		} else {
			await dispatch(setIssuesSort('dueDate', 'desc'));
		}

		ReactGA.event({
			category: 'Issue Tracker',
			action: 'Issue Tracker - Filter issues by due date',
		});
	};

	const handleMoveToHelpPage = () => {
		history.push('/help');
	};

	return !isLoaded(filterDataUsers) || !isLoaded(filterDataDepartments) || !isLoaded(filterDataSites) ? null : (
		<Translation>
			{(t) => (
				<Container>
					<Root>
						<Category>
							<Type>
								<img src={FilterIcon} alt="" />
								<span>{t('label.filterBy')}</span>
							</Type>
							<CalendarDateRangePicker
								isDisabled={isLoading}
								startDate={tempFilteredDate.startDate}
								endDate={tempFilteredDate.endDate}
								startDatePlaceholderText={t('addOn.dashboard.placeholder.startDate')}
								endDatePlaceholderText={t('addOn.dashboard.placeholder.endDate')}
								displayFormat="DD/MM/YYYY"
								onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
								hasRange={false}
							/>
							<FilterField
								name="filteredSource"
								emptyPlaceholder={t('placeholder.issuePage.bySource')}
								filledPlaceholder={t('placeholder.issuePage.bySource')}
								value={mapValuesToOptions(tempFilters.qIssueType, issueTypeMap)}
								onChange={(options: BaseSelectOptionType[]) => handleSelectIssueType(options, tempFilters.qStatus)}
								options={Object.values(issueTypeMap)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredQuestionnaire"
								emptyPlaceholder={t('placeholder.issuePage.byQuestionnaire')}
								filledPlaceholder={t('placeholder.issuePage.byQuestionnaire')}
								value={mapValuesToOptions(tempFilters.qQuestionnaire, questionnaireMap)}
								onChange={(options: BaseSelectOptionType[]) =>
									handleSelectFilters(IssueFilterQuery.QUESTIONNAIRE, options)
								}
								options={questionnaireOptions}
								dimPlaceholder
								isDisabled={
									isLoading ||
									(tempFilters.qIssueType.length === 1 && tempFilters.qIssueType.includes(IssueType.CUSTOMER_FEEDBACK))
								}
							/>
							<FilterField
								name="filteredSurvey"
								emptyPlaceholder={t('placeholder.issuePage.bySurvey')}
								filledPlaceholder={t('placeholder.issuePage.bySurvey')}
								value={mapValuesToOptions(tempFilters.qCFQuestionnaireID, surveyMap)}
								onChange={(options: BaseSelectOptionType[]) =>
									handleSelectFilters(IssueFilterQuery.CF_QUESTIONNAIRE, options)
								}
								options={surveyOptions}
								dimPlaceholder
								isDisabled={
									isLoading ||
									(tempFilters.qIssueType.length !== 0 && !tempFilters.qIssueType.includes(IssueType.CUSTOMER_FEEDBACK))
								}
							/>
							<FilterField
								name="filteredUser"
								emptyPlaceholder={t('placeholder.issuePage.byUser')}
								filledPlaceholder={t('placeholder.issuePage.byUser')}
								value={mapValuesToOptions(tempFilters.qUser, userMap)}
								onChange={(options: BaseSelectOptionType[]) => handleSelectFilters(IssueFilterQuery.USER, options)}
								options={Object.values(userMap)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredDepartment"
								emptyPlaceholder={t('placeholder.issuePage.byDepartment')}
								filledPlaceholder={t('placeholder.issuePage.byDepartment')}
								value={mapValuesToOptions(tempFilters.qDepartment, departmentMap)}
								onChange={(options: BaseSelectOptionType[]) =>
									handleSelectFilters(IssueFilterQuery.DEPARTMENT, options)
								}
								options={departmentOptions}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredSite"
								emptyPlaceholder={t('placeholder.issuePage.bySite')}
								filledPlaceholder={t('placeholder.issuePage.bySite')}
								value={mapValuesToOptions(tempFilters.qSite, siteMap)}
								onChange={(options: BaseSelectOptionType[]) => handleSelectFilters(IssueFilterQuery.SITE, options)}
								options={siteOptions}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredFlag"
								emptyPlaceholder={t('placeholder.issuePage.byFlag')}
								filledPlaceholder={t('placeholder.issuePage.byFlag')}
								value={mapValuesToOptions(tempFilters.qFlag, flagMap)}
								onChange={(options: BaseSelectOptionType[]) => handleSelectFilters(IssueFilterQuery.FLAG, options)}
								options={Object.values(flagMap)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredPriority"
								emptyPlaceholder={t('placeholder.issuePage.byPriority')}
								filledPlaceholder={t('placeholder.issuePage.byPriority')}
								value={mapValuesToOptions(tempFilters.qPriorityV2, priorityMap)}
								onChange={(options: BaseSelectOptionType[]) => handleSelectFilters(IssueFilterQuery.PRIORITY, options)}
								options={Object.values(priorityMap)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredStatus"
								emptyPlaceholder={t('placeholder.issuePage.byStatus')}
								filledPlaceholder={t('placeholder.issuePage.byStatus')}
								value={mapValuesToOptions(tempFilters.qStatus, statusMap)}
								onChange={(options: BaseSelectOptionType[]) => {
									handleSelectFilters(IssueFilterQuery.STATUS, options);
								}}
								options={getStatusOptions(tempFilters.qIssueType)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterField
								name="filteredSecondaryStatus"
								emptyPlaceholder={t('placeholder.issuePage.bySecondaryStatus')}
								filledPlaceholder={t('placeholder.issuePage.bySecondaryStatus')}
								value={mapValuesToOptions(tempFilters.qSecStatus, secondaryStatusMap)}
								onChange={(options: BaseSelectOptionType[]) => {
									handleSelectFilters(IssueFilterQuery.SECONDARY_STATUS, options);
								}}
								options={getSecondaryStatusOptions(tempFilters.qStatus, tempFilters.qSecStatus)}
								dimPlaceholder
								isDisabled={isLoading}
							/>
							<FilterButtonContainer>
								<LinkButton onClick={handleSaveFilters}>
									<MdOutlineBookmarkAdd size={24} />
									{t('label.issuePage.customFilter.saveFilter')}
								</LinkButton>
								<LinkButton onClick={handleResetFilters}>{t('button.reset')}</LinkButton>
								<OutlineButton onClick={handleApplyFilters}>{t('button.apply')}</OutlineButton>
							</FilterButtonContainer>
						</Category>
						<Category>
							<Type>
								<img src={SortIcon} alt="" />
								<span>{t('label.sortBy')}</span>
							</Type>
							<RadioContainer>
								<Radio
									isLoading={isLoading}
									isSelected={sortBy === 'createdAt'}
									onClick={() => {
										if (!isLoading) handleSortCreatedAt();
									}}
								>
									<div className="border">
										<div className="fill" />
									</div>
									<div className="content">
										<span className="text">{t('label.issuePage.createdAt')}</span>
										<SortingArrow
											color={isLoading ? '#EFEEED' : '#11998E'}
											direction={sortDirection}
											active={!isLoading && sortBy === 'createdAt'}
										/>
									</div>
								</Radio>
								<Radio
									isLoading={isLoading}
									isSelected={sortBy === 'dueDate'}
									onClick={() => {
										if (!isLoading) handleSortDueDate();
									}}
								>
									<div className="border">
										<div className="fill" />
									</div>
									<div className="content">
										<span className="text">{t('label.issuePage.dueDate')}</span>
										<SortingArrow
											color={isLoading ? '#EFEEED' : '#11998E'}
											direction={sortDirection}
											active={!isLoading && sortBy === 'dueDate'}
										/>
									</div>
								</Radio>
							</RadioContainer>
						</Category>
					</Root>
					<Tabs>
						<Tab onClick={() => handleMoveToHelpPage()}>
							<img src={HelpIcon} alt="help-icon" />
							<span>Help</span>
						</Tab>
					</Tabs>
				</Container>
			)}
		</Translation>
	);
};

const Tabs = styled.div`
	width: 100%;
`;

const Tab = styled.div<{
	isActive?: boolean;
}>`
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

	background: ${(props) => (props.isActive ? '#efeeed' : 'transparent')};

	transition: 150ms all ease-in-out;

	cursor: pointer;

	img {
		margin-right: 11px;
		filter: ${(props) => (props.isActive ? 'none' : 'grayscale(100%)')};
	}

	i {
		margin-left: 6px;
		margin-right: 13px;
	}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;

		color: #11998e;
	}

	a {
		text-decoration: none !important;
	}

	:hover {
		background: #efeeed;

		img {
			filter: none;
		}
	}
`;

const Container = styled.div`
	height: 100%;
	padding-top: 15px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding-bottom: 15px;
`;

const Root = styled.div`
	padding: 0 16px;
`;

const Type = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.5em;

	span {
		display: inline-block;
		margin-left: 6px;

		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 22px;

		color: #11998e;
	}
`;

const Category = styled.div`
	margin-bottom: 30px;

	> div:not(:first-child) {
		margin-top: 0.25em;
	}
`;

const FilterButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	margin-top: 1rem;
`;

const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 100px;

	> div:not(:first-child) {
		margin-top: 1em;
	}

	@media (min-width: 992px) {
		justify-content: flex-start;

		> div:not(:first-child) {
			margin-top: 1em;
		}
	}
`;

const Radio = styled.div<{
	isLoading?: boolean;
	isSelected?: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};

	.border {
		width: 13px;
		height: 13px;
		padding: 1px;

		border: ${({ isLoading }) => (isLoading ? '1px solid #EFEEED' : '1px solid #11998e')};
		border-radius: calc(15px / 2);

		.fill {
			height: 100%;
			width: 100%;
			background: ${({ isSelected, isLoading }) =>
				isLoading && isSelected ? '#EFEEED' : isSelected ? '#11998E' : 'transparent'};
			border-radius: calc(100% / 2);
		}
	}

	.content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.text {
		margin-left: 4px;
		font-size: 14px;
		color: ${({ isLoading }) => (isLoading ? '#EFEEED' : '#11998e')};
		transition: 0.25s ease-out;
	}

	:hover {
		.text {
			color: ${({ isLoading }) => (isLoading ? '#EFEEED' : '#0c6b63')};
		}
	}

	@media (min-width: 992px) {
		font-size: 10px;
		line-height: 15px;

		.border {
			width: 15px;
			height: 15px;
			border-radius: calc(13px / 2);
		}
	}
`;

const DatePickerWrapper = styled.div`
	display: inline-flex;
	justify-content: center;
	text-align: center;

	margin-left: 0;

	.DateRangePicker {
		.custom-arrow {
			margin-left: 10px;
			margin-right: 10px;
			font-size: 10px;
			line-height: 15px;
			color: #535353;
			width: 10px;
		}

		.DateRangePickerInput {
			background-color: transparent;

			.DateInput {
				width: calc((100% / 2) - 30px);
				min-width: 120px;
				border-radius: 2px;
				margin-top: 0.25em;

				.DateInput_input {
					height: 30px;
					font-size: 12px;
					border-radius: 2px;
					border: 1px solid transparent;
					background: #f2f5f6;

					color: #535353;
				}

				.DateInput_input__focused {
					border: 1px solid #5049b2;
				}

				.DateInput_input__disabled {
					background: #efeeed;
					opacity: 1;
					color: #8f8f8f;
				}
			}
		}

		.DateRangePickerInput__disabled {
			background-color: transparent;
		}
	}

	@media (min-width: 992px) {
		margin-bottom: 0;
		text-align: unset;

		.DateRangePicker {
			.DateRangePickerInput {
				.DateInput {
					width: 130px;
				}
			}
		}
	}
`;

const mapStateToProps = (state: RootState) => ({
	/** Filter Data */
	tempFilters: state.issues.tempFilters,
	tempFilteredDate: state.issues.tempFilteredDate,
	sortBy: state.issues.sortBy,
	sortDirection: state.issues.sortDirection,

	/** Relational dependencies */
	issuesData: state.issues.issuesData,
	filterDataDepartments: state.departments.departments,
	filterDataUsers: state.users.users,
	filterDataSites: state.siteCompact.data,

	/** Page setup */
	isLoading: state.issues.isLoading,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(connect(mapStateToProps), firebaseConnect(), withRouter)(memo(IssueFilter));

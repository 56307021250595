import { components, OptionProps } from 'core/presentation/ui/Select/ReactSelectExtended';
import { CheckboxLabel, CheckboxLabelWrapper, CheckboxOption as CheckboxOptionWrapper } from './CheckboxOption.styles';
import Checkbox from '../../primaryCheckbox/Checkbox';
import React from 'react';

const CheckboxOption = ({ children, ...props }: OptionProps<any>) => {
	return (
		<div>
			<components.Option {...props}>
				<CheckboxOptionWrapper
					onClick={(event) => {
						props.selectOption(props.data);
						event.stopPropagation();
						event.preventDefault();
					}}
				>
					<Checkbox disabled={props.isDisabled} checked={props.isSelected} shouldPropagate />
					<CheckboxLabelWrapper>
						<CheckboxLabel isDisabled={props.isDisabled}>{props.label}</CheckboxLabel>
					</CheckboxLabelWrapper>
				</CheckboxOptionWrapper>
			</components.Option>
		</div>
	);
};

export default CheckboxOption;
